import styled from '@mui/material/styles/styled';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

export const Title = styled(DialogTitle)(({theme}) => ({
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.primary.main,
    margin: '0 auto'
}));

export const Actions = styled(DialogActions)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 24,
    alignItems: 'center'
});

export const CloseButton = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1)
}));