import React, { createContext, memo, useContext, useLayoutEffect, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
// Local files
import { AppBarRoot, Content } from './Header.styled';

export const HeaderContext = createContext({});

const Header = ({ handleOpenMobileMenu }) => {
  const { content = <div />, setContentRef } = useContext(HeaderContext);

  const ref = useRef(null);

  useLayoutEffect(() => setContentRef(ref), [setContentRef]);

  return (
    <AppBarRoot position="fixed" elevation={0} >
      <Toolbar style={{height: 64}}>
        <Hidden mdUp>
          <IconButton size="large" onClick={handleOpenMobileMenu} edge="start" aria-label="menu" style={{color: '#fff'}}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Content ref={ref}>{content}</Content>
      </Toolbar>
    </AppBarRoot>
  );
};

Header.propTypes = {
  handleOpenMobileMenu: PropTypes.func
};

export default memo(Header);
