import {
  getListChargesRoutine,
  getChargesRoutine,
  getChargeRoutine,
  createChargeRoutine,
  updateChargeRoutine,
  deleteChargeRoutine,
  clearLocalListChargesRoutine,
  clearLocalChargesRoutine,
  clearLocalActiveChargeRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  charge: { id: '', name: '', charge_type: '', basic_rate_types: [], mile_type: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListChargesRoutine.SUCCESS: {
      const { charges: data, pagination } = action.payload.data;

      return { ...state, list: { data: _uniqBy([...state.list.data, ...data], 'id'), pagination } };
    }
    case getChargesRoutine.SUCCESS: {
      const { data: { charges, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...charges], pagination } } : { ...state, all: { data: charges, pagination } };
    }
    case getChargeRoutine.SUCCESS: {
      const { charge } = action.payload.data;

      return { ...state, charge: { ...state.charge, ...charge } };
    }
    case createChargeRoutine.SUCCESS: {
      const { charge } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[charge], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateChargeRoutine.SUCCESS: {
      const { response: { status }, charge } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === charge.id ? { ...item, ...charge } : item) }, ...(status === 204 && { charge: { ...state.charge, ...charge } }) };
    }
    case deleteChargeRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalListChargesRoutine.SUCCESS: {
      return { ...state, list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalChargesRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveChargeRoutine.SUCCESS: {
      return { ...state, charge: { id: '', name: '', charge_type: '', basic_rate_types: [], mile_type: '' } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
