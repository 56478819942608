import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import { 
  getListCharges as getListChargesAction,
  getCharges as getChargesAction, 
  getCharge as getChargeAction, 
  createCharge as createChargeAction,
  updateCharge as updateChargeAction,
  deleteCharge as deleteChargeAction,
  clearLocalListCharges as clearLocalListChargesAction,
  clearLocalCharges as clearLocalChargesAction,
  clearLocalActiveCharge as clearLocalActiveChargeAction
} from 'actions/charges';

const useCharges = () => {
  const dispatch = useDispatch();
  const getListCharges = useCallback(params => dispatch(getListChargesAction(params)), [dispatch]);
  const getCharges = useCallback(params => dispatch(getChargesAction(params)), [dispatch]);
  const getCharge = useCallback(id => dispatch(getChargeAction(id)), [dispatch]);
  const createCharge = useCallback(charge => dispatch(createChargeAction(charge)), [dispatch]);
  const updateCharge = useCallback(charge => dispatch(updateChargeAction(charge)), [dispatch]);
  const deleteCharge = useCallback(id => dispatch(deleteChargeAction(id)), [dispatch]);
  const clearLocalListCharges = useCallback(() => dispatch(clearLocalListChargesAction()), [dispatch]);
  const clearLocalCharges = useCallback(() => dispatch(clearLocalChargesAction()), [dispatch]);
  const clearLocalActiveCharge = useCallback(() => dispatch(clearLocalActiveChargeAction()), [dispatch]);

  return { 
    getListCharges,
    getCharges, 
    getCharge, 
    createCharge, 
    updateCharge, 
    deleteCharge, 
    clearLocalListCharges,
    clearLocalCharges, 
    clearLocalActiveCharge 
  };

};

export default useCharges;