import {
  setErrorRoutine,
  hideErrorRoutine
} from 'actions';
import _get from 'lodash/get';

const initialState = {
  message: '',
  type: '',
  isOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setErrorRoutine.SUCCESS: {
      const type = _get(action.payload, 'response.data.error.type') || '';
      const status = _get(action.payload, 'response.status') || null;
      const defaultMessage = action.payload.toString();
      let customErrorText = false;
      let message = '';

      if (_get(action.payload, 'response.data.error.message')) {
        message = action.payload.response.data.error.message;
        customErrorText = true;
      }
      if (status === 400) {
        message = 'Authorization data invalid';
        customErrorText = true;
      }
      if (status === 401) {
        message = 'Access token invalid';
        customErrorText = true;
      }
      if (status === 403) {
        message = 'Access forbidden';
        customErrorText = true;
      }
      if (status === 404) {
        message = 'Record not found';
        customErrorText = true;
      }

      return { ...state, type, message: customErrorText ? message : defaultMessage, isOpen: true };
    }
    case hideErrorRoutine.SUCCESS: {
      return { ...state, type: '', message: '', isOpen: false };
    }
    default: {
      return state;
    }
  };
};

export default reducer;