import {
  setSuccessRoutine,
  hideSuccessRoutine
} from 'actions';

const initialState = {
  message: '',
  isOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setSuccessRoutine.SUCCESS: {
      return { ...state, message: action.payload, isOpen: true };
    }
    case hideSuccessRoutine.SUCCESS: {
      return { ...state, message: '', isOpen: false };
    }
    default: {
      return state;
    }
  };
};

export default reducer;