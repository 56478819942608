import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import _omit from 'lodash/omit';
import {
  getUsersRoutine,
  getCompanyUsersRoutine,
  getUserRoutine,
  createUserRoutine,
  updateUserRoutine,
  deleteUserRoutine,
  clearLocalUsersRoutine,
  clearLocalActiveUserRoutine,
  clearLocalCompanyUsersRoutine
} from 'actions';

const shortAttributes = [
  'id',
  'first_name',
  'last_name',
  'email',
  'roles',
  { photo: ['id', 'representations']}
];
const fullAttributes = [
  ...shortAttributes,
  'previous_names',
  {companies: ['id', 'name', 'locations']},
  'birth_at',
  'phone',
  'second_phone',
  'emergency_phone',
  'second_emergency_phone',
  'address',
  'latitude',
  'longitude',
  'notifications'
];

export const getUsers = getThunkActionCreator(
  getUsersRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' }, query = '', roles, company_id = null }) => {
    getUsers.getUsersCancel && getUsers.getUsersCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/users`, {
      params: { access_token, limit, offset, orders, roles, ...(!!company_id && { company_id }), ...(!!query && { query }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getUsers.getUsersCancel = cancel })
    });
  }
);
export const getCompanyUsers = getThunkActionCreator(
  getCompanyUsersRoutine,
  async ({ company_id, limit = 25, offset = 0, orders = { created_at: 'desc' }, query = '' }) => {
    getCompanyUsers.getCompanyUsersCancel && getCompanyUsers.getCompanyUsersCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/companies/${company_id}/users`, {
      params: { access_token, limit, offset, orders, ...(!!query && { query }), attributes: shortAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getCompanyUsers.getCompanyUsersCancel = cancel })
    });
  }
);
export const getUser = getThunkActionCreator(
  getUserRoutine,
  async (id) => {
    getUser.getUserCancel && getUser.getUserCancel();
    const { token: access_token } = await sessionService.loadSession();

    return await axios.get(`/users/${id}`, {
      params: { access_token, attributes: fullAttributes },
      cancelToken: new CancelToken(function executor(cancel) { getUser.getUserCancel = cancel })
    });
  }
);
export const createUser = getThunkActionCreator(
  createUserRoutine,
  async (user) => {
    const { token: access_token } = await sessionService.loadSession();

    return await axios.post(`/users`, { access_token, user, attributes: shortAttributes });
  }
);
export const updateUser = getThunkActionCreator(
  updateUserRoutine,
  async ({ id, ...fields }) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.patch(`/users/${id}`, { access_token, user: _omit(fields, ['localPhoto']) });

    return { response, user: { id, ...fields } };
  }
);
export const deleteUser = getThunkActionCreator(
  deleteUserRoutine,
  async (id) => {
    const { token: access_token } = await sessionService.loadSession();
    const response = await axios.delete(`/users/${id}`, { params: { access_token }});

    return { response, id };
  }
);
export const clearLocalUsers = getThunkActionCreator(clearLocalUsersRoutine, async () => {});
export const clearLocalActiveUser = getThunkActionCreator(clearLocalActiveUserRoutine, async () => {});
export const clearLocalCompanyUsers = getThunkActionCreator(clearLocalCompanyUsersRoutine, async () => {});