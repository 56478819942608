import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  shadows: [
    'none',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)',
    ' 0 1px 5px 0 rgba(127, 131, 227, 0.21)'
  ],
  typography: {
    fontFamily: [
      '"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'
    ].join(','),
    h4: {// Form Title
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#225082',
    },
    h5: {// appbar title
    },
    h6: {
      fontWeight: 600,
    },
    caption: {
      color: 'rgba(161, 164, 170, 1)',    //text.hint
    }
  },
  palette: {
    primary: {
      main: '#3f51b5',
      dark: '#303f9f'
    },
    secondary: {
      main: '#f50057'
    },
    text: {
      primary: 'rgba(18, 18, 18, 1)',      //#121212
      secondary: 'rgba(104, 106, 109, 1)', //#686A6D
      hint: 'rgba(161, 164, 170, 1)',      //#A1A4AA
      disabled: 'rgba(161, 164, 170, 1)',  //#A1A4AA
    },
    background: {
      default: '#fff',
      accent: '#F7F8FD'
    },
    divider: 'rgba(121, 153, 174, 1)',
    border: {
      default: 'rgba(121, 153, 174, 1)',   //border card and tables
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      contained: {
        textTransform: 'uppercase'
      }
    }
  }
});

export default theme;