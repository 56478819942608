import {
  openConfirmationDialogRoutine, closeConfirmationDialogRoutine,
  openPasswordDialogRoutine, closePasswordDialogRoutine,
  openInfoDialogRoutine, closeInfoDialogRoutine
} from 'actions';

export const initialState = {
  confirmation: { open: false, type: '', id: '' },
  password: { open: false, id: '', reason: ''},
  info: { open: false }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    /***
     * Confirmation
     ***/
    case openConfirmationDialogRoutine.SUCCESS: {
      const { type, id } = action.payload;

      return { ...state, confirmation: { open: true, type, id } };
    }
    case closeConfirmationDialogRoutine.SUCCESS: {
      return { ...state, confirmation: initialState.confirmation };
    }
    /***
     * Password
     ***/
    case openPasswordDialogRoutine.SUCCESS: {
      const { id, reason } = action.payload;
      return { ...state, password: { open: true, id, reason } };
    }
    case closePasswordDialogRoutine.SUCCESS: {
      return { ...state, password: initialState.password };
    }
    /***
     * Info
     ***/
    case openInfoDialogRoutine.SUCCESS: {
      return { ...state, info: { open: true } };
    }
    case closeInfoDialogRoutine.SUCCESS: {
      return { ...state, info: { open: false } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;