import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getListCompaniesRoutine,
  getCompaniesRoutine,
  getCompanyRoutine,
  createCompanyRoutine,
  updateCompanyRoutine,
  deleteCompanyRoutine,
  clearLocalListCompaniesRoutine,
  clearLocalCompaniesRoutine,
  clearLocalActiveCompanyRoutine
} from 'actions';

const attributes = [
  'id', 
  'locations',
  'name',
  'time_zone',
  'commission_value',
  'mileage_calculation',
  'stripe_account_id',
  'stripe_charges_enabled',
  'subscription_expired_at'
];

export const getListCompanies = getThunkActionCreator(
  getListCompaniesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null }) => {
    getListCompanies.getListCompaniesCancel && getListCompanies.getListCompaniesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/companies`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getListCompanies.getListCompaniesCancel = cancel })
      }
    );
  }
);

export const getCompanies = getThunkActionCreator(
  getCompaniesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null }) => {
    getCompanies.getCompaniesCancel && getCompanies.getCompaniesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/companies`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getCompanies.getCompaniesCancel = cancel })
      }
    );
  }
);

export const getCompany = getThunkActionCreator(
  getCompanyRoutine,
  async (id) => {
    getCompany.getCompanyCancel && getCompany.getCompanyCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/companies/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCompany.getCompanyCancel = cancel })
      }
    );
  }
);

export const createCompany = getThunkActionCreator(
  createCompanyRoutine,
  async (company) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/companies`, { access_token: currentSession.token, company });
  }
);

export const updateCompany = getThunkActionCreator(
  updateCompanyRoutine,
  async (company) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/companies/${company.id}`, { access_token: currentSession.token, company });

    return { response, company };
  }
);

export const deleteCompany = getThunkActionCreator(
  deleteCompanyRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/companies/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalListCompanies = getThunkActionCreator(
  clearLocalListCompaniesRoutine,
  async () => true
);

export const clearLocalCompanies = getThunkActionCreator(
  clearLocalCompaniesRoutine,
  async () => true
);

export const clearLocalActiveCompany = getThunkActionCreator(
  clearLocalActiveCompanyRoutine,
  async () => true
);

