import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getTypesRoutine,
  getTypeRoutine,
  createTypeRoutine,
  updateTypeRoutine,
  deleteTypeRoutine,
  clearLocalTypesRoutine,
  clearLocalActiveTypeRoutine
} from 'actions';

const attributes = ['id', 'name'];

export const getTypes = getThunkActionCreator(
  getTypesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null }) => {
    getTypes.getTypesCancel && getTypes.getTypesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/types`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getTypes.getTypesCancel = cancel })
      }
    );
  }
);

export const getType = getThunkActionCreator(
  getTypeRoutine,
  async (id) => {
    getType.getTypeCancel && getType.getTypeCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/types/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getType.getTypeCancel = cancel })
      }
    );
  }
);

export const createType = getThunkActionCreator(
  createTypeRoutine,
  async (type) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/types`, { access_token: currentSession.token, type });
  }
);

export const updateType = getThunkActionCreator(
  updateTypeRoutine,
  async (type) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/types/${type.id}`, { access_token: currentSession.token, type });

    return { response, type };
  }
);

export const deleteType = getThunkActionCreator(
  deleteTypeRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/types/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalTypes = getThunkActionCreator(
  clearLocalTypesRoutine,
  async () => true
);

export const clearLocalActiveType = getThunkActionCreator(
  clearLocalActiveTypeRoutine,
  async () => true
);