import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import { 
  getInsuranceAccountsList as getInsuranceAccountsListAction,
  getInsuranceAccounts as getInsuranceAccountsAction,
  getInsuranceAccount as getInsuranceAccountAction,
  getCableInsuranceAccount as getCableInsuranceAccountAction,
  createInsuranceAccount as createInsuranceAccountAction, 
  updateInsuranceAccount as updateInsuranceAccountAction, 
  deleteInsuranceAccount as deleteInsuranceAccountAction,
  registerIsscInsuranceAccount as registerIsscInsuranceAccountAction, 
  deregisterIsscInsuranceAccount as deregisterIsscInsuranceAccountAction, 
  loginInsuranceAccount as loginInsuranceAccountAction, 
  logoutInsuranceAccount as logoutInsuranceAccountAction,
  clearLocalInsuranceAccountsList as clearLocalInsuranceAccountsListAction,
  clearLocalInsuranceAccounts as clearLocalInsuranceAccountsAction,
  clearLocalActiveInsuranceAccount as clearLocalActiveInsuranceAccountAction} from 'actions/insuranceAccounts';

  const useInsuranceAccounts = () => {
    const dispatch = useDispatch();
    const getInsuranceAccountsList = useCallback(params => dispatch(getInsuranceAccountsListAction(params)), [dispatch]);
    const getInsuranceAccounts = useCallback(params => dispatch(getInsuranceAccountsAction(params)), [dispatch]);
    const getInsuranceAccount = useCallback(params => dispatch(getInsuranceAccountAction(params)), [dispatch]);
    const getCableInsuranceAccount = useCallback(params => dispatch(getCableInsuranceAccountAction(params)), [dispatch]);
    const createInsuranceAccount = useCallback(params => dispatch(createInsuranceAccountAction(params)), [dispatch]);
    const updateInsuranceAccount = useCallback(params => dispatch(updateInsuranceAccountAction(params)), [dispatch]);
    const deleteInsuranceAccount = useCallback(params => dispatch(deleteInsuranceAccountAction(params)), [dispatch]);
    const registerIsscInsuranceAccount = useCallback(params => dispatch(registerIsscInsuranceAccountAction(params)), [dispatch]);
    const deregisterIsscInsuranceAccount = useCallback(params => dispatch(deregisterIsscInsuranceAccountAction(params)), [dispatch]);
    const loginInsuranceAccount = useCallback(params => dispatch(loginInsuranceAccountAction(params)), [dispatch]);
    const logoutInsuranceAccount = useCallback(params => dispatch(logoutInsuranceAccountAction(params)), [dispatch]);
    const clearLocalInsuranceAccountsList = useCallback(() => dispatch(clearLocalInsuranceAccountsListAction()), [dispatch]);
    const clearLocalInsuranceAccounts = useCallback(() => dispatch(clearLocalInsuranceAccountsAction()), [dispatch]);
    const clearLocalActiveInsuranceAccount = useCallback(() => dispatch(clearLocalActiveInsuranceAccountAction()), [dispatch]);
  
    return { 
      getInsuranceAccountsList,
      getInsuranceAccounts,
      getInsuranceAccount,
      getCableInsuranceAccount,
      createInsuranceAccount, 
      updateInsuranceAccount, 
      deleteInsuranceAccount,
      registerIsscInsuranceAccount, 
      deregisterIsscInsuranceAccount, 
      loginInsuranceAccount, 
      logoutInsuranceAccount,
      clearLocalInsuranceAccountsList,
      clearLocalInsuranceAccounts,
      clearLocalActiveInsuranceAccount
    };
  
  };
  
  export default useInsuranceAccounts;