import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';

export const ShowPasswordIconButton = styled(IconButton)(({theme}) => ({
  color: theme.palette.primary.main,
  marginRight: 4,
  '&:hover': {
    background: 'transparent'
  }
}));
