import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import {
  getAdmins as getAdminsAction,
  getAdmin as getAdminAction,
  createAdmin as createAdminAction,
  updateAdmin as updateAdminAction,
  deleteAdmin as deleteAdminAction,
  clearLocalAdmins as clearLocalAdminsAction,
  clearLocalActiveAdmin as clearLocalActiveAdminAction
} from 'actions/admins';

const useAdmins = () => {
  const dispatch = useDispatch();
  const getAdmins = useCallback(params => dispatch(getAdminsAction(params)), [dispatch]);
  const getAdmin = useCallback(id => dispatch(getAdminAction(id)), [dispatch]);
  const createAdmin = useCallback(admin => dispatch(createAdminAction(admin)), [dispatch]);
  const updateAdmin = useCallback(admin => dispatch(updateAdminAction(admin)), [dispatch]);
  const deleteAdmin = useCallback(id => dispatch(deleteAdminAction(id)), [dispatch]);
  const clearLocalAdmins = useCallback(() => dispatch(clearLocalAdminsAction()), [dispatch]);
  const clearLocalActiveAdmin = useCallback(() => dispatch(clearLocalActiveAdminAction()), [dispatch]);

  return { getAdmins, getAdmin, createAdmin, updateAdmin, deleteAdmin, clearLocalAdmins, clearLocalActiveAdmin };

};

export default useAdmins;