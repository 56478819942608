import React, { memo, useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { sessionService } from 'redux-react-session';
// Dialogs
import Confirmation from 'components/Dialogs/Confirmation/Confirmation';
import Password from 'components/Dialogs/Password/Password';
import Info from 'components/Dialogs/Info/Info';
// Local files
import Navigation from '../Navigation/Navigation';
import useCustomSelector from 'hooks/useCustomSelector';
import Header, { HeaderContext } from '../../Common/Header/Header';
import Cable from 'components/Cable/Cable';
import useProfile from 'hooks/useProfile';
import useError from 'hooks/useError';
import useSession from 'hooks/useSession';

const drawerWidth = '240px';

const Layout = () => {
  const theme = useTheme();
  const { getProfile } = useProfile();
  const { setError } = useError();
  const location = useLocation();
  const profileFetched = useCustomSelector(state => !!state.profile.admin.id);
  const [contentRef, setContentRef] = useState(null);
  const { authenticated, checked } = useSession();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [token, setToken] = useState(null);
  const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const checkSession = () => sessionService.loadSession().then(({ token: t }) => setToken(t));

    if (authenticated && checked && !profileFetched) {
      getProfile()
      .then(() => !token && checkSession())
      .catch(e => setError(e));
    }
  }, [authenticated, checked, profileFetched, getProfile, setError, token]);

  useEffect(() => {
    return () => {
      if (!!token) setToken(null);
    }
  }, [authenticated, checked, token]);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  if (checked) {
    if (!authenticated) return <Navigate to='/sign_in' state={{ from: location }} replace />;
    if (location.pathname === '/') return <Navigate to='/admins' replace />;
    return (
      <>
        {token && <Cable token={token} />}
        <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: { md: 'space-between'} }}> 
        {/*DESKTOP*/}
          <Drawer
            variant='permanent'
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, boxShadow: 'unset', border: 'none', backgroundColor: theme.palette.primary.main },
            }}
            open
          >
            <Navigation />
          </Drawer>
        {/*MOBILE*/}
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, boxShadow: 'unset', border: 'none', backgroundColor: theme.palette.primary.main },
            }}
          >
            <Navigation />
          </Drawer>
          {lessThanMedium && <HeaderContext.Provider value={{ contentRef, setContentRef }}>
            <Header handleOpenMobileMenu={() => setMobileOpen(true)} />
          </HeaderContext.Provider>}
          <Box
            component="main"
            sx={{ flexGrow: 1, width: '100%', maxWidth: { md: `calc(100% - ${drawerWidth})` }, [theme.breakpoints.down('md')]: {paddingTop: '64px'} }}
          >
            <Outlet />
          </Box>
        </Box>
        <Confirmation />
        <Password />
        <Info />
      </>
    );
  }
  return <div />;
};

export default memo(Layout);