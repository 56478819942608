import {
  getBlobRoutine,
  createBlobRoutine
} from 'actions';

const initialState = {
  blob: { id: '', representations: [] }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getBlobRoutine.SUCCESS: {
      const { blob } = action.payload.data;

      return { ...state, blob: { ...state.blob, ...blob } };
    }
    case createBlobRoutine.SUCCESS: {
      const { blob } = action.payload.data;
    
      return { ...state, blob: { ...state.blob, ...blob } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;