import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  openConfirmationDialog as openConfirmationDialogAction, 
  closeConfirmationDialog as closeConfirmationDialogAction,
  openPasswordDialog as openPasswordDialogAction,
  closePasswordDialog as closePasswordDialogAction,
  openInfoDialog as openInfoDialogAction,
  closeInfoDialog as closeInfoDialogAction
} from 'actions/dialogs';

const useDialogs = () => {
  const dispatch = useDispatch();
  const openConfirmationDialog = useCallback(({ type, id }) => dispatch(openConfirmationDialogAction({ type, id })), [dispatch]);
  const closeConfirmationDialog = useCallback(() => dispatch(closeConfirmationDialogAction()), [dispatch]);
  const openPasswordDialog = useCallback(params => dispatch(openPasswordDialogAction(params)), [dispatch]);
  const closePasswordDialog = useCallback(() => dispatch(closePasswordDialogAction()), [dispatch]);
  const openInfoDialog = useCallback(() => dispatch(openInfoDialogAction()), [dispatch]);
  const closeInfoDialog = useCallback(params => dispatch(closeInfoDialogAction(params)), [dispatch]);

  return {
    openConfirmationDialog,
    closeConfirmationDialog,
    openPasswordDialog,
    closePasswordDialog,
    openInfoDialog,
    closeInfoDialog
  };
};

export default useDialogs;