import styled from '@mui/material/styles/styled';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

export const SnackbarErrorContent = styled(SnackbarContent)(({theme}) => ({
  backgroundColor: theme.palette.error.dark,
  boxShadow: 'none'
}));

export const Message = styled('span')({
  display: 'flex',
  alignItems: 'center'
});

export const Error = styled(ErrorIcon)({
  width: 20,
  height: 20,
  marginRight: 10,
  marginLeft: 10
});

export const Close = styled(CloseIcon)({
  width: 20,
  height: 20
});