import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getServicesRoutine,
  getServiceRoutine,
  createServiceRoutine,
  updateServiceRoutine,
  deleteServiceRoutine,
  clearLocalServicesRoutine,
  clearLocalActiveServiceRoutine,
} from 'actions';

const attributes = ['id', 'name', 'service_type', {default_charges: ['id', 'basic_rate_type', { charge: ['id', 'name', 'basic_rate_types'] } ]}];

export const getServices = getThunkActionCreator(
  getServicesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null, service_type = null }) => {
    getServices.getServicesCancel && getServices.getServicesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/services`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          ...(!!service_type && { service_type }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getServices.getServicesCancel = cancel })
      }
    );
  }
);

export const getService = getThunkActionCreator(
  getServiceRoutine,
  async (id) => {
    getService.getServiceCancel && getService.getServiceCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/services/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getService.getServiceCancel = cancel })
      }
    );
  }
);

export const createService = getThunkActionCreator(
  createServiceRoutine,
  async (service) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/services`, { access_token: currentSession.token, service });
  }
);

export const updateService = getThunkActionCreator(
  updateServiceRoutine,
  async (service) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/services/${service.id}`, { access_token: currentSession.token, service });

    return { response, service };
  }
);

export const deleteService = getThunkActionCreator(
  deleteServiceRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/services/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalServices = getThunkActionCreator(
  clearLocalServicesRoutine,
  async () => true
);

export const clearLocalActiveService = getThunkActionCreator(
  clearLocalActiveServiceRoutine,
  async () => true
);