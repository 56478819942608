import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getInsuranceAccountsRoutine,
  getInsuranceAccountsListRoutine,
  getInsuranceAccountRoutine,
  getCableInsuranceAccountRoutine,
  createInsuranceAccountRoutine,
  updateInsuranceAccountRoutine,
  deleteInsuranceAccountRoutine,
  clearLocalInsuranceAccountsRoutine,
  clearLocalInsuranceAccountsListRoutine,
  clearLocalActiveInsuranceAccountRoutine,
  registerIsscInsuranceAccountRoutine,
  deregisterIsscInsuranceAccountRoutine,
  loginInsuranceAccountRoutine,
  logoutInsuranceAccountRoutine
} from 'actions';

const attributes = [
  'id', 
  {company: 
    [
      'id', 
      'name', 
      'locations', 
      'commission_value', 
      'mileage_calculation', 
      'stripe_account_id', 
      'stripe_charges_enabled', 
      'subscription_expired_at' 
    ]
  },
  'location',
  {insurance_accountable:
    [
      'client_id',
      'contractor_id',
      'location_id',
      'tax_id',
      'contact_name',
      'contact_phone',
      'username',
      'password',
      'digital_dispatch_logged_in',
      'digital_dispatch_token',
      'provider_email',
      'description',
      'categories',
      'vehicle_types',
      'urgently_registered'
    ]
  },
  'insurance_accountable_type',
  'name', 
  'provider_name',
  'provider_phone',
  'order_auto_acceptance',
  'logged_in' 
];

export const getInsuranceAccounts = getThunkActionCreator(
  getInsuranceAccountsRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null, company_id = null, insurance_accountable_type = null }) => {
    getInsuranceAccounts.getInsuranceAccountsCancel && getInsuranceAccounts.getInsuranceAccountsCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/insurance_accounts`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          ...(!!company_id && { company_id }),
          ...(!!insurance_accountable_type && { insurance_accountable_type }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getInsuranceAccounts.getInsuranceAccountsCancel = cancel })
      }
    );
  }
);

export const getInsuranceAccountsList = getThunkActionCreator(
  getInsuranceAccountsListRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null }) => {
    getInsuranceAccountsList.getInsuranceAccountsListCancel && getInsuranceAccountsList.getInsuranceAccountsListCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/insurance_accounts`,
      {
        params: { access_token: currentSession.token, limit, offset, orders, ...(!!query && { query }), attributes },
        cancelToken: new CancelToken(function executor(cancel) { getInsuranceAccountsList.getInsuranceAccountsListCancel = cancel })
      }
    );
  }
);

export const getInsuranceAccount = getThunkActionCreator(
  getInsuranceAccountRoutine,
  async (id) => {
    getInsuranceAccount.getInsuranceAccountCancel && getInsuranceAccount.getInsuranceAccountCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/insurance_accounts/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getInsuranceAccount.getInsuranceAccountCancel = cancel })
      }
    );
  }
);

export const getCableInsuranceAccount = getThunkActionCreator(
  getCableInsuranceAccountRoutine,
  async (id) => {
    getCableInsuranceAccount.getCableInsuranceAccountCancel && getCableInsuranceAccount.getCableInsuranceAccountCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/insurance_accounts/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCableInsuranceAccount.getCableInsuranceAccountCancel = cancel })
      }
    );
  }
);

export const createInsuranceAccount = getThunkActionCreator(
  createInsuranceAccountRoutine,
  async (insurance_account) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/insurance_accounts`, { access_token: currentSession.token, insurance_account, attributes});
  }
);

export const updateInsuranceAccount = getThunkActionCreator(
  updateInsuranceAccountRoutine,
  async (insurance_account) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/insurance_accounts/${insurance_account.id}`, { access_token: currentSession.token, insurance_account });

    return { response, insurance_account };
  }
);

export const deleteInsuranceAccount = getThunkActionCreator(
  deleteInsuranceAccountRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/insurance_accounts/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalInsuranceAccounts = getThunkActionCreator(
  clearLocalInsuranceAccountsRoutine,
  async () => true
);
export const clearLocalInsuranceAccountsList = getThunkActionCreator(
  clearLocalInsuranceAccountsListRoutine,
  async () => true
);

export const clearLocalActiveInsuranceAccount = getThunkActionCreator(
  clearLocalActiveInsuranceAccountRoutine,
  async () => true
);

export const registerIsscInsuranceAccount = getThunkActionCreator(
  registerIsscInsuranceAccountRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/insurance_accounts/${id}/register`, { access_token: currentSession.token });
  }
);

export const deregisterIsscInsuranceAccount = getThunkActionCreator(
  deregisterIsscInsuranceAccountRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/insurance_accounts/${id}/deregister`, { access_token: currentSession.token });
  }
);

export const loginInsuranceAccount= getThunkActionCreator(
  loginInsuranceAccountRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/insurance_accounts/${id}/login`, { access_token: currentSession.token });
  }
);

export const logoutInsuranceAccount = getThunkActionCreator(
  logoutInsuranceAccountRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/insurance_accounts/${id}/logout`, { access_token: currentSession.token });
  }
);