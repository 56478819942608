import {
  getUsersRoutine,
  getCompanyUsersRoutine,
  getUserRoutine,
  createUserRoutine,
  updateUserRoutine,
  deleteUserRoutine,
  clearLocalUsersRoutine,
  clearLocalActiveUserRoutine,
  clearLocalCompanyUsersRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  company: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  user: {
    id: '',
    companies: [],
    first_name: '',
    last_name: '',
    photo: null,
    previous_names: '',
    roles: [],
    birth_at: '',
    email: '',
    phone: '',
    second_phone: '',
    emergency_phone: '',
    second_emergency_phone: '',
    address: '',
    latitude: 0,
    longitude: 0,
    notifications: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getUsersRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...users], pagination } } : { ...state, all: { data: users, pagination } };
    }
    case getCompanyUsersRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;

      return { ...state, company: { data: [...state.company.data, ...users], pagination } };
    }
    case getUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;
      const data = _map(state.all.data, (item) => item.id === user.id ? { ...item, ...user } : item);
      const updatedUser = { ...state.user, ...user };

      return { ...state, user: updatedUser, all: { ...state.all, data } };
    }
    case createUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[user], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateUserRoutine.SUCCESS: {
      const { response: { status }, user } = action.payload;
      const updatedUser = { ...state.user, ...user, ...(!!user.photo && { photo: { id: user.photo, localPhoto: user.localPhoto } }) };
      const data = _map(state.all.data, (item) => item.id === user.id ? { ...item, ...user, ...(!!user.photo && { photo: { id: user.photo, localPhoto: user.localPhoto } }) } : item);

      return { ...state, ...(status === 204 && { user: updatedUser }), all: { ...state.all, data } };
    }
    case deleteUserRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case clearLocalUsersRoutine.SUCCESS: {
      return { ...state, all: initialState.all };
    }
    case clearLocalActiveUserRoutine.SUCCESS: {
      return { ...state, user: initialState.user };
    }
    case clearLocalCompanyUsersRoutine.SUCCESS: {
      return { ...state, company: initialState.company };
    }
    default: {
      return state;
    }
  };
};

export default reducer;