import React, { memo } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
// Local files
import { ListButton } from './MenuItem.styled';

const MenuItem = ({ icon, label, to, otherProps }) => {
  return (
    <ListButton
      component={NavLink}
      to={to}
      {...otherProps}
    >
      <ListItemIcon sx={{color: '#fff', minWidth: 40}}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListButton>
  )
}

export default memo(MenuItem);