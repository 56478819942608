import {
  getProfileRoutine,
  updateProfileRoutine
} from 'actions';

const initialState = {
  admin: { id: '', email: '', phone: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getProfileRoutine.SUCCESS: {
      const { admin } = action.payload.data;

      return { ...state, admin: { ...state.admin, ...admin } };
    }
    case updateProfileRoutine.SUCCESS: {
      const { admin, response: { status } } = action.payload;

      return { ...state, ...(status === 204 && { admin: { ...state.admin, ...admin } }) };
    }
    default: {
      return state;
    }
  };
};

export default reducer;