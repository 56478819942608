import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  createSession as createSessionAction,
  deleteSession as deleteSessionAction
} from 'actions/sessions';

const useSessions = () => {
  const dispatch = useDispatch();
  const createSession = useCallback(({ email, password, confirmation_code }) => dispatch(createSessionAction({ email, password, confirmation_code })), [dispatch]);
  const deleteSession = useCallback(() => dispatch(deleteSessionAction()), [dispatch]);

  return { createSession, deleteSession };
};

export default useSessions;