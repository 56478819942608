import { createThunkRoutine } from 'redux-thunk-routine';
// Admins
export const getAdminsRoutine = createThunkRoutine('GET_ADMINS');
export const getAdminRoutine = createThunkRoutine('GET_ADMIN');
export const createAdminRoutine = createThunkRoutine('CREATE_ADMIN');
export const updateAdminRoutine = createThunkRoutine('UPDATE_ADMIN');
export const deleteAdminRoutine = createThunkRoutine('DELETE_ADMIN');
export const clearLocalAdminsRoutine = createThunkRoutine('CLEAR_LOCAL_ADMINS');
export const clearLocalActiveAdminRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_ADMIN');
// App
export const openAppDrawerRoutine = createThunkRoutine('OPEN_APP_DRAWER');
export const closeAppDrawerRoutine = createThunkRoutine('CLOSE_APP_DRAWER');
// Blobs
export const getBlobRoutine = createThunkRoutine('GET_BLOB');
export const createBlobRoutine = createThunkRoutine('CREATE_BLOB');
//Charges
export const getListChargesRoutine = createThunkRoutine('GET_LIST_CHARGES');
export const getChargesRoutine = createThunkRoutine('GET_CHARGES');
export const getChargeRoutine = createThunkRoutine('GET_CHARGE');
export const createChargeRoutine = createThunkRoutine('CREATE_CHARGE');
export const updateChargeRoutine = createThunkRoutine('UPDATE_CHARGE');
export const deleteChargeRoutine = createThunkRoutine('DELETE_CHARGE');
export const clearLocalListChargesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_CHARGES');
export const clearLocalChargesRoutine = createThunkRoutine('CLEAR_LOCAL_CHARGES');
export const clearLocalActiveChargeRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CHARGE');
// Companies
export const getListCompaniesRoutine = createThunkRoutine('GET_LIST_COMPANIES');
export const getCompaniesRoutine = createThunkRoutine('GET_COMPANIES');
export const getCompanyRoutine = createThunkRoutine('GET_COMPANY');
export const createCompanyRoutine = createThunkRoutine('CREATE_COMPANY');
export const updateCompanyRoutine = createThunkRoutine('UPDATE_COMPANY');
export const deleteCompanyRoutine = createThunkRoutine('DELETE_COMPANY');
export const clearLocalListCompaniesRoutine = createThunkRoutine('CLEAR_LOCAL_LIST_COMPANIES');
export const clearLocalCompaniesRoutine = createThunkRoutine('CLEAR_LOCAL_COMPANIES');
export const clearLocalActiveCompanyRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_COMPANY');
// Default Charges
export const createDefaultChargeRoutine = createThunkRoutine('CREATE_DEFAULT_CHARGE');
export const updateDefaultChargeRoutine = createThunkRoutine('UPDATE_DEFAULT_CHARGE');
export const deleteDefaultChargeRoutine = createThunkRoutine('DELETE_DEFAULT_CHARGE');
// Dialogs
export const openConfirmationDialogRoutine = createThunkRoutine('OPEN_CONFIRMATION_DIALOG');
export const closeConfirmationDialogRoutine = createThunkRoutine('CLOSE_CONFIRMATION_DIALOG');
export const openPasswordDialogRoutine = createThunkRoutine('OPEN_PASSWORD_DIALOG');
export const closePasswordDialogRoutine = createThunkRoutine('CLOSE_PASSWORD_DIALOG');
export const openInfoDialogRoutine = createThunkRoutine('OPEN_INFO_DIALOG');
export const closeInfoDialogRoutine = createThunkRoutine('CLOSE_INFO_DIALOG');
// Dispatches
export const getDispatchesRoutine = createThunkRoutine('GET_DISPATCHES');
export const getDispatchRoutine = createThunkRoutine('GET_DISPATCH');
export const clearLocalDispatchesRoutine = createThunkRoutine('CLEAR_LOCAL_DISPATCHES');
export const clearLocalActiveDispatchRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_DISPATCH');
// Errors
export const setErrorRoutine = createThunkRoutine('SET_ERROR');
export const hideErrorRoutine = createThunkRoutine('HIDE_ERROR');
// Insurance accounts
export const getInsuranceAccountsRoutine = createThunkRoutine('GET_INSURANCE_ACCOUNTS');
export const getInsuranceAccountsListRoutine = createThunkRoutine('GET_INSURANCE_ACCOUNTS_LIST');
export const getInsuranceAccountRoutine = createThunkRoutine('GET_INSURANCE_ACCOUNT');
export const getCableInsuranceAccountRoutine = createThunkRoutine('GET_CABLE_INSURANCE_ACCOUNT');
export const createInsuranceAccountRoutine = createThunkRoutine('CREATE_INSURANCE_ACCOUNT');
export const updateInsuranceAccountRoutine = createThunkRoutine('UPDATE_INSURANCE_ACCOUNT');
export const deleteInsuranceAccountRoutine = createThunkRoutine('DELETE_INSURANCE_ACCOUNT');
export const clearLocalInsuranceAccountsRoutine = createThunkRoutine('CLEAR_LOCAL_INSURANCE_ACCOUNTS');
export const clearLocalInsuranceAccountsListRoutine = createThunkRoutine('CLEAR_LOCAL_INSURANCE_ACCOUNTS_LIST');
export const clearLocalActiveInsuranceAccountRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_INSURANCE_ACCOUNT');
export const registerIsscInsuranceAccountRoutine = createThunkRoutine('REGISTER_ISSC_INSURANCE_ACCOUNT');
export const deregisterIsscInsuranceAccountRoutine = createThunkRoutine('DEREGISTER_ISSC_INSURANCE_ACCOUNT');
export const loginInsuranceAccountRoutine = createThunkRoutine('LOGIN_INSURANCE_ACCOUNT');
export const logoutInsuranceAccountRoutine = createThunkRoutine('LOGOUT_INSURANCE_ACCOUNT');
// Notifications
export const updateNotificationRoutine = createThunkRoutine('UPDATE_NOTIFICATION');
// Profiles
export const getProfileRoutine = createThunkRoutine('GET_PROFILE');
export const updateProfileRoutine = createThunkRoutine('UPDATE_PROFILE');
export const connectIsscRoutine = createThunkRoutine('CONNECT_ISSC');
export const disconnectIsscRoutine = createThunkRoutine('DISCONNECT_ISSC');
// Services
export const getServicesRoutine = createThunkRoutine('GET_SERVICES');
export const getServiceRoutine = createThunkRoutine('GET_SERVICE');
export const createServiceRoutine = createThunkRoutine('CREATE_SERVICE');
export const updateServiceRoutine = createThunkRoutine('UPDATE_SERVICE');
export const deleteServiceRoutine = createThunkRoutine('DELETE_SERVICE');
export const clearLocalServicesRoutine = createThunkRoutine('CLEAR_LOCAL_SERVICES');
export const clearLocalActiveServiceRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_SERVICE');
//Confirmations (2-factor auth)
export const sendConfirmationCodeRoutine = createThunkRoutine('SEND_CONFIRMATION_CODE');
// Sessions
export const createSessionRoutine = createThunkRoutine('CREATE_SESSION');
export const deleteSessionRoutine = createThunkRoutine('DELETE_SESSION');
// Store
export const clearStoreRoutine = createThunkRoutine('CLEAR_STORE');
// Success
export const setSuccessRoutine = createThunkRoutine('SET_SUCCESS');
export const hideSuccessRoutine = createThunkRoutine('HIDE_SUCCESS');
// Types
export const getTypesRoutine = createThunkRoutine('GET_TYPES');
export const getTypeRoutine = createThunkRoutine('GET_TYPE');
export const createTypeRoutine = createThunkRoutine('CREATE_TYPE');
export const updateTypeRoutine = createThunkRoutine('UPDATE_TYPE');
export const deleteTypeRoutine = createThunkRoutine('DELETE_TYPE');
export const clearLocalTypesRoutine = createThunkRoutine('CLEAR_LOCAL_TYPES');
export const clearLocalActiveTypeRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_TYPE');
// Users
export const getUsersRoutine = createThunkRoutine('GET_USERS');
export const getCompanyUsersRoutine = createThunkRoutine('GET_COMPANY_USERS');
export const getUserRoutine = createThunkRoutine('GET_USER');
export const createUserRoutine = createThunkRoutine('CREATE_USER');
export const updateUserRoutine = createThunkRoutine('UPDATE_USER');
export const deleteUserRoutine = createThunkRoutine('DELETE_USER');
export const clearLocalUsersRoutine = createThunkRoutine('CLEAR_LOCAL_USERS');
export const clearLocalActiveUserRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_USER');
export const clearLocalCompanyUsersRoutine = createThunkRoutine('CLEAR_LOCAL_COMPANY_USERS');