import {
  getAdminsRoutine,
  getAdminRoutine,
  createAdminRoutine,
  updateAdminRoutine,
  deleteAdminRoutine,
  clearLocalAdminsRoutine,
  clearLocalActiveAdminRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  admin: { id: '', email: '', phone: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getAdminsRoutine.SUCCESS: {
      const { data: { admins, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...admins], pagination } } : { ...state, all: { data: admins, pagination } };
    }
    case getAdminRoutine.SUCCESS: {
      const { admin } = action.payload.data;

      return { ...state, admin: { ...state.admin, ...admin } };
    }
    case createAdminRoutine.SUCCESS: {
      const { admin } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[admin], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateAdminRoutine.SUCCESS: {
      const { response: { status }, admin } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === admin.id ? { ...item, ...admin } : item) }, ...(status === 204 && { admin: { ...state.admin, ...admin } }) };
    }
    case deleteAdminRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalAdminsRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveAdminRoutine.SUCCESS: {
      return { ...state, admin: { id: '', email: '', phone: '' } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
