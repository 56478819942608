import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';

export const Root = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  paddingTop: 24,
  paddingBottom: 8
});

export const Head = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 16px',
  '& > :not(:last-child)': {
    marginRight: 8
  }
});
  
export const Logo = styled('img')({
  userSelect: 'none',
  width: 120,
  height: 'auto'
});

export const Spacer = styled('div')({
  flexGrow: 1,
  flexShrink: 0,
  minHeight: 24
});

export const LogoutButton = styled(Button)(({theme}) => ({
  color: '#fff',
  backgroundColor: theme.palette.primary.dark
}));

