import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import { 
  getTypes as getTypesAction, 
  getType as getTypeAction, 
  createType as createTypeAction,
  updateType as updateTypeAction,
  deleteType as deleteTypeAction,
  clearLocalTypes as clearLocalTypesAction,
  clearLocalActiveType as clearLocalActiveTypeAction
} from 'actions/types';

const useTypes = () => {
  const dispatch = useDispatch();
  const getTypes = useCallback(params => dispatch(getTypesAction(params)), [dispatch]);
  const getType = useCallback(id => dispatch(getTypeAction(id)), [dispatch]);
  const createType = useCallback(type => dispatch(createTypeAction(type)), [dispatch]);
  const updateType = useCallback(type => dispatch(updateTypeAction(type)), [dispatch]);
  const deleteType = useCallback(id => dispatch(deleteTypeAction(id)), [dispatch]);
  const clearLocalTypes = useCallback(() => dispatch(clearLocalTypesAction()), [dispatch]);
  const clearLocalActiveType = useCallback(() => dispatch(clearLocalActiveTypeAction()), [dispatch]);

  return { getTypes, getType, createType, updateType, deleteType, clearLocalTypes, clearLocalActiveType };

};

export default useTypes;