import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import {
  createDefaultCharge as createDefaultChargeAction,
  updateDefaultCharge as updateDefaultChargeAction,
  deleteDefaultCharge as deleteDefaultChargeAction,
} from 'actions/defaultCharges';

const useDefaultCharges = () => {
  const dispatch = useDispatch();
  const createDefaultCharge = useCallback(defaultCharge => dispatch(createDefaultChargeAction(defaultCharge)), [dispatch]);
  const updateDefaultCharge = useCallback(defaultCharge => dispatch(updateDefaultChargeAction(defaultCharge)), [dispatch]);
  const deleteDefaultCharge = useCallback(id => dispatch(deleteDefaultChargeAction(id)), [dispatch]);

  return { createDefaultCharge, updateDefaultCharge, deleteDefaultCharge };

};

export default useDefaultCharges;