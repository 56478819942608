import React, { forwardRef, memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
// Local files
import { Title, Actions, CloseButton } from './BaseDialog.styled';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BaseDialog = ({ open, onClose, hasTransition = true, title, content, actions, fullWidth = true, maxWidth = "md", children, ...otherProps }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={!!hasTransition ? Transition : undefined}
      PaperProps={{
        sx: {
          minWidth: '300px',
          borderRadius: '16px'
        }
      }}
      aria-labelledby="app-dialog"
      {...otherProps}
    >
      {!!title && <Title>{title}</Title>}
      <CloseButton size="large" aria-label="close" onClick={onClose}><CloseIcon /></CloseButton>
      {!!content && <DialogContent>{content}</DialogContent>}
      {!!children && children}
      {!!actions && <Actions>{actions}</Actions>}
    </Dialog>
  );
}

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasTransition: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.node
};

export default memo(BaseDialog);