import {
  getTypesRoutine,
  getTypeRoutine,
  createTypeRoutine,
  updateTypeRoutine,
  deleteTypeRoutine,
  clearLocalTypesRoutine,
  clearLocalActiveTypeRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  type: { id: '', name: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getTypesRoutine.SUCCESS: {
      const { data: { types, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...types], pagination } } : { ...state, all: { data: types, pagination } };
    }
    case getTypeRoutine.SUCCESS: {
      const { type } = action.payload.data;

      return { ...state, type: { ...state.type, ...type } };
    }
    case createTypeRoutine.SUCCESS: {
      const { type } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[type], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateTypeRoutine.SUCCESS: {
      const { response: { status }, type } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === type.id ? { ...item, ...type } : item) }, ...(status === 204 && { type: { ...state.type, ...type } }) };
    }
    case deleteTypeRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalTypesRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveTypeRoutine.SUCCESS: {
      return { ...state, type: { id: '', name: '' } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
