import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import { 
  getServices as getServicesAction, 
  getService as getServiceAction, 
  createService as createServiceAction,
  updateService as updateServiceAction,
  deleteService as deleteServiceAction,
  clearLocalServices as clearLocalServicesAction,
  clearLocalActiveService as clearLocalActiveServiceAction
} from 'actions/services';

const useServices = () => {
  const dispatch = useDispatch();
  const getServices = useCallback(params => dispatch(getServicesAction(params)), [dispatch]);
  const getService = useCallback(id  => dispatch(getServiceAction(id)), [dispatch]);
  const createService = useCallback(service => dispatch(createServiceAction(service)), [dispatch]);
  const updateService = useCallback(service => dispatch(updateServiceAction(service)), [dispatch]);
  const deleteService = useCallback(id => dispatch(deleteServiceAction(id)), [dispatch]);
  const clearLocalServices = useCallback(() => dispatch(clearLocalServicesAction()), [dispatch]);
  const clearLocalActiveService = useCallback(() => dispatch(clearLocalActiveServiceAction()), [dispatch]);

  return { getServices, getService, createService, updateService, deleteService, clearLocalServices, clearLocalActiveService };

};

export default useServices;