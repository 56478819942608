import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';

//matches +############## pattern, i.e. correct phone number format. '+' symbol cannot be deleted
//for example +12252542524
export const phoneNumberRegExp = /^\+\d{0,14}$/;

export const checkEmptyString = (str) => {
  return typeof str === 'string' ? !!str.replace(/\s/g, '').length : !!str;
};

export const checkValidEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

  return re.test(String(email).toLowerCase());
};

export const checkValidPhone = (phone) => {
  return phoneNumberRegExp.test(String(phone)) && phone.length > 2
}

export const parseDispatchText = (text, type) => {
  const dispatchFields = (type === "digital_dispatch") ? [
    'client_id',
    'contractor_id',
    'dispatch_id',
    'time_stamp',
    'job_id',
    'max_eta',
    'text',
    'phone',
  ]:[
    'message_id',
    'provider_id',
    'notes', 
    'controller'
  ]
  const parsedText = text
    .replace(/=>/g, ': ')
    .replace(/[^\d\s_,.:\-A-Z]/gi, '')
    .split(/,/)
    .filter((value) => dispatchFields.find((item) => value.includes(item)));

  return parsedText.map((str) => str.replace(':', ''));

};



export const getRandomString = (length) => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getRepresentation = (representations, variant) => {
  const foundRepresentation = _find(representations, (r) => r.name === variant);
  return foundRepresentation?.url ?? null;
};

export const needToUpdateField = (a, b) => {
  if (typeof a === 'string' || typeof b === 'string') {
    return a !== b && (checkEmptyString(a) || checkEmptyString(b));
  }
  if (typeof a === 'object' || typeof b === 'object') {
    return !_isEqual(a, b) && (!_isEmpty(a) || !_isEmpty(b));
  }
  if (Array.isArray(a) || Array.isArray(b)) {
    return !_isEqualWith(a.sort(), b.sort(), (f, s) => f === s);
  }
  if (typeof a === 'number' || typeof b === 'number') {
    return a !== b;
  }
  return a !== b;
};

export const getDateWithoutTime = (date) => {
  return date.substring(0, date.indexOf('T'));
};

export const getDateWithNullTimeZone = (date) => {
  return date.substring(0, date.indexOf('T')) + 'T00:00:00.000Z';
};

export const toIsoString = (date) => {
  let tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};
