import React, { memo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
// Local files
import { Success, Message, CheckCircleOutline, Close } from './SuccessSnackbar.styled';
import useCustomSelector from 'hooks/useCustomSelector';
import useSuccess from 'hooks/useSuccess';

const SuccessSnackbar = () => {
  const { hideSuccess } = useSuccess();
  const { isOpen, message } = useCustomSelector(state => state.success);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={hideSuccess}
    >
      <Success
        aria-describedby="client-snackbar"
        message={<Message id="client-snackbar"><CheckCircleOutline />{message}</Message>}
        action={[
          <IconButton key="close" size="large" aria-label="close" color="inherit" onClick={hideSuccess}>
            <Close />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export default memo(SuccessSnackbar);