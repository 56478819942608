import { openAppDrawerRoutine, closeAppDrawerRoutine } from 'actions';
import { reasons, drawerTypes } from 'helpers/constants';

const { ADMIN, BASIC_CHARGE, FEE_CHARGE, COMPANY, DIGITAL_DISPATCH_INSURANCE_ACCOUNT, URGENTLY_INSURANCE_ACCOUNT, DISPATCH, SERVICE, TYPE, USER } = reasons;
const { FORM, DETAILS } = drawerTypes;

const initialState = {
  admin: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  basicCharge: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  feeCharge: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  company: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  digital_dispatch_insurance_account: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false } },
  urgently_insurance_account: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false } },
  dispatch: { detailsDrawer: { open: false }, id: '', },
  service: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  type: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' },
  user: { detailsDrawer: { open: false }, formDrawer: { edit: false, open: false }, id: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case openAppDrawerRoutine.SUCCESS: {
      const { reason, edit, id, type } = action.payload;
;
      return {
        ...state, 
        ...(reason === ADMIN && type === FORM && { admin: { ...state.admin, formDrawer: {open: true, edit}, id } }),
        ...(reason === ADMIN && type === DETAILS && { admin: { ...state.admin, detailsDrawer: {open: true}, id } }),
        ...(reason === BASIC_CHARGE && type === FORM && { basicCharge: { ...state.basicCharge, formDrawer: {open: true, edit}, id } }),
        ...(reason === BASIC_CHARGE && type === DETAILS && { basicCharge: { ...state.basicCharge, detailsDrawer: {open: true}, id } }),
        ...(reason === FEE_CHARGE && type === FORM && { feeCharge: { ...state.feeCharge, formDrawer: {open: true, edit}, id } }),
        ...(reason === FEE_CHARGE && type === DETAILS && { feeCharge: { ...state.feeCharge, detailsDrawer: {open: true}, id } }),
        ...(reason === COMPANY && type === FORM && { company: { ...state.company, formDrawer: {open: true, edit}, id } }),
        ...(reason === COMPANY && type === DETAILS && { company: { ...state.company, detailsDrawer: {open: true}, id } }),
        ...(reason === DIGITAL_DISPATCH_INSURANCE_ACCOUNT && type === FORM && { digital_dispatch_insurance_account: { ...state.digital_dispatch_insurance_account, formDrawer: {open: true, edit}, id } }),
        ...(reason === DIGITAL_DISPATCH_INSURANCE_ACCOUNT && type === DETAILS && { digital_dispatch_insurance_account: { ...state.digital_dispatch_insurance_account, detailsDrawer: {open: true}, id } }),
        ...(reason === URGENTLY_INSURANCE_ACCOUNT && type === FORM && { urgently_insurance_account: { ...state.urgently_insurance_account, formDrawer: {open: true, edit}, id } }),
        ...(reason === URGENTLY_INSURANCE_ACCOUNT && type === DETAILS && { urgently_insurance_account: { ...state.urgently_insurance_account, detailsDrawer: {open: true}, id } }),
        ...(reason === DISPATCH && type === DETAILS && { dispatch: { detailsDrawer: {open: true}, id } }),
        ...(reason === SERVICE && type === FORM && { service: { ...state.service, formDrawer: {open: true, edit}, id } }),
        ...(reason === SERVICE && type === DETAILS && { service: { ...state.service, detailsDrawer: {open: true}, id } }),
        ...(reason === TYPE && type === FORM && { type: { ...state.type, formDrawer: {open: true, edit}, id } }),
        ...(reason === TYPE && type === DETAILS && { type: { ...state.type, detailsDrawer: {open: true}, id } }),
        ...(reason === USER && type === FORM && { user: { ...state.user, formDrawer: {open: true, edit}, id } }),
        ...(reason === USER && type === DETAILS && { user: { ...state.user, detailsDrawer: {open: true}, id } })
      };
    }
    case closeAppDrawerRoutine.SUCCESS: {
      const { reason } = action.payload;

      return {
        ...state,
        ...(reason === ADMIN && { admin: initialState.admin }),
        ...(reason === BASIC_CHARGE && { basicCharge: initialState.basicCharge }),
        ...(reason === FEE_CHARGE && { feeCharge: initialState.feeCharge }),
        ...(reason === COMPANY && { company: initialState.company }),
        ...(reason === DIGITAL_DISPATCH_INSURANCE_ACCOUNT && { digital_dispatch_insurance_account: initialState.digital_dispatch_insurance_account }),
        ...(reason === URGENTLY_INSURANCE_ACCOUNT && { urgently_insurance_account: initialState.urgently_insurance_account }),
        ...(reason === DISPATCH && { dispatch: initialState.dispatch }),
        ...(reason === SERVICE && { service: initialState.service }),
        ...(reason === TYPE && { type: initialState.type }),
        ...(reason === USER && { user: initialState.user })
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;