import { combineReducers } from 'redux';
import { clearStoreRoutine } from 'actions';
import { sessionReducer as session } from 'redux-react-session';
// Admins
import admins from './admins/admins';
// App
import app from './app/app';
// Blobs
import blobs from './blobs/blobs';
//Charges
import charges from './charges/charges';
// Companies
import companies from './companies/companies';
// Default Charges
import defaultCharges from './defaultCharges/defaultCharges';
// Dialogs 
import dialogs from './dialogs/dialogs';
// Dispatches
import dispatches from './dispatches/dispatches';
// Error
import error from './error/error';
// InsuranceAccounts
import insuranceAccounts from './insuranceAccounts/insuranceAccounts';
// Profile
import profile from './profiles/profiles';
//Services
import services from './services/services'
// Sessions
import sessions from './sessions/sessions';
// Success
import success from './success/success';
// Types
import types from './types/types'
// Users
import users from './users/users';

const appReducer = combineReducers({
  admins,
  app,
  blobs,
  charges,
  companies,
  defaultCharges,
  dialogs,
  insuranceAccounts,
  dispatches,
  error,
  profile,
  services,
  session,
  sessions,
  success,
  types,
  users
});

const rootReducer = (state, action) => {
  if (clearStoreRoutine.isSuccessAction(action)) {
    const { session } = state;

    state = { session };
  }

  return appReducer(state, action);
};

export default rootReducer;