import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import { updateProfile as updateProfileAction, getProfile as getProfileAction } from 'actions/profiles';

const useProfile = () => {
  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(getProfileAction()), [dispatch]);
  const updateProfile = useCallback(params => dispatch(updateProfileAction(params)), [dispatch]);

  return { getProfile, updateProfile };

};

export default useProfile;