import { memo, useMemo, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
// Local files
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import { reasons } from "helpers/constants";
import { 
  DELETE_ADMIN,
  DELETE_BASIC_CHARGE,
  DELETE_FEE_CHARGE,
  DELETE_COMPANY,
  DELETE_DEFAULT_CHARGE,
  DELETE_INSURANCE_ACCOUNT,
  DELETE_ADDITIONAL_SERVICE,
  DELETE_BASIC_SERVICE,
  DELETE_TYPE,
  DELETE_USER
} from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useAdmins from 'hooks/useAdmins';
import useCharges from 'hooks/useCharges';
import useCompanies from 'hooks/useCompanies';
import useCustomSelector from 'hooks/useCustomSelector';
import useDefaultCharges from 'hooks/useDefaultCharges';
import useDialogs from 'hooks/useDialogs';
import useError from 'hooks/useError';
import useInsuranceAccounts from 'hooks/useInsuranceAccounts';
import useServices from 'hooks/useServices';
import useSuccess from 'hooks/useSuccess';
import useTypes from 'hooks/useTypes';
import useUsers from 'hooks/useUsers';
import { DialogButton } from './Confirmation.styled';

const isAdminPageOpen = (currentPage) => {
  return /^\/admins\/.+$/.test(currentPage.pathname);
}

const { ADMIN, BASIC_CHARGE, FEE_CHARGE, COMPANY, DIGITAL_DISPATCH_INSURANCE_ACCOUNT, URGENTLY_INSURANCE_ACCOUNT, SERVICE, TYPE, USER} = reasons;

const Confirmation = () => {
  const navigate = useNavigate();
  const currentPage = useLocation()
  const { closeDrawer } = useApp();
  const { deleteAdmin, getAdmins, clearLocalActiveAdmin } = useAdmins();
  const { deleteCharge, getCharges } = useCharges();
  const { deleteCompany, getCompanies } = useCompanies();
  const { deleteInsuranceAccount, getInsuranceAccounts } = useInsuranceAccounts();
  const { deleteDefaultCharge } = useDefaultCharges();
  const { deleteService, getServices, getService } = useServices();
  const { deleteType, getTypes } = useTypes();
  const { deleteUser, getUsers } = useUsers();
  const { open, type, id } = useCustomSelector(state => state.dialogs.confirmation);
  const { closeConfirmationDialog } = useDialogs();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { limit: limitAdmins, count: countAdmins, offset: offsetAdmins, total_count: totalCountAdmins } = useCustomSelector(state => state.admins.all.pagination);
  const { limit: limitCharges, count: countCharges, offset: offsetCharges, total_count: totalCountCharges } = useCustomSelector(state => state.charges.all.pagination);
  const { limit: limitCompanies, count: countCompanies, offset: offsetCompanies, total_count: totalCountCompanies } = useCustomSelector(state => state.companies.all.pagination);
  const { limit: limitInsuranceAccounts, count: countInsuranceAccounts, offset: offsetInsuranceAccounts, total_count: totalCountInsuranceAccounts } = useCustomSelector(state => state.insuranceAccounts.all.pagination);
  const { limit: limitServices, count: countServices, offset: offsetServices, total_count: totalCountServices } = useCustomSelector(state => state.services.all.pagination);
  const { limit: limitTypes, count: countTypes, offset: offsetTypes, total_count: totalCountTypes } = useCustomSelector(state => state.types.all.pagination);
  const { limit: limitUsers, count: countUsers, offset: offsetUsers, total_count: totalCountUsers } = useCustomSelector(state => state.users.all.pagination);
  const { service } = useCustomSelector(state => state.services);
  const content = useMemo(() => {
    if (type === DELETE_ADMIN) return 'Are you sure you want to delete this admin?';
    if (type === DELETE_BASIC_CHARGE) return 'Are you sure you want to delete this basic charge?';
    if (type === DELETE_FEE_CHARGE) return 'Are you sure you want to delete this fee charge?';
    if (type === DELETE_COMPANY) return 'Are you sure you want to delete this company?';
    if (type === DELETE_DEFAULT_CHARGE) return 'Are you sure you want to delete this default charge?';
    if (type === DELETE_INSURANCE_ACCOUNT) return 'Are you sure you want to delete this insurance account?';
    if (type === DELETE_ADDITIONAL_SERVICE) return 'Are you sure you want to delete this additional service?';
    if (type === DELETE_BASIC_SERVICE) return 'Are you sure you want to delete this basic service?';
    if (type === DELETE_TYPE) return 'Are you sure you want to delete this type?';
    if (type === DELETE_USER) return 'Are you sure you want to delete this user?';
    return '';
  }, [type]);
  const [processing, setProcessing] = useState(false);

  const handleConfirmAction = () => {
    setProcessing(true);
    if (type === DELETE_ADMIN) {
      deleteAdmin(id)
      .then(() => clearLocalActiveAdmin())
      .then(() => {
        if  (totalCountAdmins > countAdmins + offsetAdmins ) {
          getAdmins({limit: 1, offset: offsetAdmins + (limitAdmins - 1)});
        }
      })
      .then(() => setSuccess('Admin successfully removed')
        .then(() =>
          closeDrawer(ADMIN)
          .then(closeConfirmationDialog)
          .then(() => isAdminPageOpen(currentPage) ? navigate('/admins') : undefined)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_BASIC_CHARGE) {
      deleteCharge(id)
      .then(() => {
        if  (totalCountCharges > countCharges + offsetCharges ) {
          getCharges({limit: 1, offset: offsetCharges + (limitCharges - 1), charge_type: 'basic'});
        }
      })
      .then(() => setSuccess('Basic Charge successfully removed')
        .then(() => 
          closeDrawer(BASIC_CHARGE)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_FEE_CHARGE) {
      deleteCharge(id)
      .then(() => {
        if  (totalCountCharges > countCharges + offsetCharges ) {
          getCharges({limit: 1, offset: offsetCharges + (limitCharges - 1), charge_type: 'fee'});
        }
      })
      .then(() => setSuccess('Fee Charge successfully removed')
        .then(() => 
          closeDrawer(FEE_CHARGE)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_COMPANY) {
      deleteCompany(id)
      .then(() => {
        if  (totalCountCompanies > countCompanies + offsetCompanies ) {
          getCompanies({limit: 1, offset: offsetCompanies + (limitCompanies - 1)});
        }
      })
      .then(() => setSuccess('Company successfully removed')
        .then(() => 
          closeDrawer(COMPANY)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_DEFAULT_CHARGE) {
      deleteDefaultCharge(id)
      .then(() => setSuccess('Default Charge successfully removed').then(closeConfirmationDialog))
      .then(() => getService(service.id))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_INSURANCE_ACCOUNT) {
      deleteInsuranceAccount(id)
      .then(() => {
        if  (totalCountInsuranceAccounts > countInsuranceAccounts + offsetInsuranceAccounts ) {
          getInsuranceAccounts({limit: 1, offset: offsetInsuranceAccounts + (limitInsuranceAccounts - 1)});
        }
      })
      .then(() => setSuccess('Insurance Account successfully removed')
        .then(() => {
          closeDrawer(DIGITAL_DISPATCH_INSURANCE_ACCOUNT);
          closeDrawer(URGENTLY_INSURANCE_ACCOUNT)
          .then(closeConfirmationDialog)
        }
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_ADDITIONAL_SERVICE) {
      deleteService(id)
      .then(() => {
        if  (totalCountServices > countServices + offsetServices ) {
          getServices({limit: 1, offset: offsetServices + (limitServices - 1), service_type: 'additional'});
        }
      })
      .then(() => setSuccess('Additional Service successfully removed')
        .then(() => 
          closeDrawer(SERVICE)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_BASIC_SERVICE) {
      deleteService(id)
      .then(() => {
        if  (totalCountServices > countServices + offsetServices ) {
          getServices({limit: 1, offset: offsetServices + (limitServices - 1), service_type: 'basic'});
        }
      })
      .then(() => setSuccess('Basic Service successfully removed')
        .then(() => 
          closeDrawer(SERVICE)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_TYPE) {
      deleteType(id)
      .then(() => {
        if  (totalCountTypes > countTypes + offsetTypes ) {
          getTypes({limit: 1, offset: offsetTypes + (limitTypes - 1)});
        }
      })
      .then(() => setSuccess('Type successfully removed')
        .then(() => 
          closeDrawer(TYPE)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_USER) {
      deleteUser(id)
      .then(() => {
        if  (totalCountUsers > countUsers + offsetUsers ) {
          getUsers({limit: 1, offset: offsetUsers + (limitUsers - 1)});
        }
      })
      .then(() => setSuccess('User successfully removed')
        .then(() => 
          closeDrawer(USER)
          .then(closeConfirmationDialog)
        )
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  const noButton = <DialogButton variant="contained" color="secondary" disabled={processing} onClick={closeConfirmationDialog}>No</DialogButton>
  const yesButton = <DialogButton variant="contained" color="primary" disabled={processing} onClick={handleConfirmAction}>Yes</DialogButton>

  return (
    <BaseDialog
      open={open}
      onClose={() => !processing && closeConfirmationDialog()}
      maxWidth="xs"
      title="Are you sure?"
      content={content}
      actions={<div>{yesButton}{noButton}</div>}
    />
  );
};

export default memo(Confirmation);