import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getAdminsRoutine,
  getAdminRoutine,
  createAdminRoutine,
  updateAdminRoutine,
  deleteAdminRoutine,
  clearLocalAdminsRoutine,
  clearLocalActiveAdminRoutine
} from 'actions';

const attributes = ['id', 'email', 'phone'];

export const getAdmins = getThunkActionCreator(
  getAdminsRoutine,
  async ({ limit = 25, offset = 0, orders = { email: 'asc' }, query = null }) => {
    getAdmins.getAdminsCancel && getAdmins.getAdminsCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/admins`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getAdmins.getAdminsCancel = cancel })
      }
    );
  }
);

export const getAdmin = getThunkActionCreator(
  getAdminRoutine,
  async (id) => {
    getAdmin.getAdminCancel && getAdmin.getAdminCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/admins/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getAdmin.getAdminCancel = cancel })
      }
    );
  }
);

export const createAdmin = getThunkActionCreator(
  createAdminRoutine,
  async (admin) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/admins`, { access_token: currentSession.token, admin });
  }
);

export const updateAdmin = getThunkActionCreator(
  updateAdminRoutine,
  async (admin) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/admins/${admin.id}`, { access_token: currentSession.token, admin });

    return { response, admin };
  }
);

export const deleteAdmin = getThunkActionCreator(
  deleteAdminRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/admins/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalAdmins = getThunkActionCreator(
  clearLocalAdminsRoutine,
  async () => true
);

export const clearLocalActiveAdmin = getThunkActionCreator(
  clearLocalActiveAdminRoutine,
  async () => true
);