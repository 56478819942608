import {
  getDispatchesRoutine,
  getDispatchRoutine,
  clearLocalDispatchesRoutine,
  clearLocalActiveDispatchRoutine
} from 'actions';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  dispatch: { id: '', text: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDispatchesRoutine.SUCCESS: {
      const { dispatches: data, pagination } = action.payload.data;

      return { ...state, all: { data, pagination } };
    }
    case getDispatchRoutine.SUCCESS: {
      const { dispatch } = action.payload.data;

      return { ...state, dispatch: { ...state.dispatch, ...dispatch } };
    }
    case clearLocalDispatchesRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveDispatchRoutine.SUCCESS: {
      return { ...state, dispatch: { id: '', email: '' } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
