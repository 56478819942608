import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import {
  getListCompanies as getListCompaniesAction,
  getCompanies as getCompaniesAction,
  getCompany as getCompanyAction,
  createCompany as createCompanyAction,
  updateCompany as updateCompanyAction,
  deleteCompany as deleteCompanyAction,
  clearLocalListCompanies as clearLocalListCompaniesAction,
  clearLocalCompanies as clearLocalCompaniesAction,
  clearLocalActiveCompany as clearLocalActiveCompanyAction
} from 'actions/companies';

const useCompanies = () => {
  const dispatch = useDispatch();
  const getListCompanies = useCallback(params => dispatch(getListCompaniesAction(params)), [dispatch]);
  const getCompanies = useCallback(params => dispatch(getCompaniesAction(params)), [dispatch]);
  const getCompany = useCallback(id => dispatch(getCompanyAction(id)), [dispatch]);
  const createCompany = useCallback(company => dispatch(createCompanyAction(company)), [dispatch]);
  const updateCompany = useCallback(company => dispatch(updateCompanyAction(company)), [dispatch]);
  const deleteCompany = useCallback(id => dispatch(deleteCompanyAction(id)), [dispatch]);
  const clearLocalListCompanies = useCallback(() => dispatch(clearLocalListCompaniesAction()), [dispatch]);
  const clearLocalCompanies = useCallback(() => dispatch(clearLocalCompaniesAction()), [dispatch]);
  const clearLocalActiveCompany = useCallback(() => dispatch(clearLocalActiveCompanyAction()), [dispatch]);

  return { 
    getListCompanies, 
    getCompanies, 
    getCompany, 
    createCompany, 
    updateCompany, 
    deleteCompany, 
    clearLocalListCompanies,
    clearLocalCompanies, 
    clearLocalActiveCompany 
  };
};

export default useCompanies;