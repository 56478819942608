import React, { memo, useState, useCallback, useEffect } from 'react';
import ActionCable from 'actioncable';
// Local files
import { wsURL } from 'apis';
// Helpers
import { cableMessageTypes } from 'helpers/constants';
// Hooks
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useInsuranceAccounts from 'hooks/useInsuranceAccounts';

const { DIGITAL_DISPATCH_INSURANCE_ACCOUNT_UPDATED, WEBHOOK } = cableMessageTypes;

const Cable = ({ token }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { getCableInsuranceAccount } = useInsuranceAccounts();
  const [data, setData] = useState(null);

  useEffect(() => {
    const cable = ActionCable.createConsumer(`${wsURL}?access_token=${token}`);

    cable.subscriptions.create('NotificationsChannel', { received: (data) => setData(data) });

    return () => {
      !!cable && cable.disconnect();
    };
  }, [token]);

  const handleInsuranceAccount = useCallback(({ insurance_account_id }) => {
    getCableInsuranceAccount(insurance_account_id).catch((error) => setError(error));
  }, [getCableInsuranceAccount, setError]);
  const handleWebhook = useCallback(({ body }) => {
    body.Result.includes('Success') ? setSuccess(`${body.Event[0].toUpperCase() + body.Event.slice(1)} ${body.Result}`) : 
    setError(`${body.Event[0].toUpperCase() + body.Event.slice(1)} ${body.Result}. ${body.Description}`);
  }, [setSuccess, setError]);

  useEffect(() => {
    if (!!data) {
      data.type === DIGITAL_DISPATCH_INSURANCE_ACCOUNT_UPDATED && handleInsuranceAccount(data);
      data.type === WEBHOOK && handleWebhook(data);
    }
  }, [data, handleInsuranceAccount, handleWebhook]);

  return <div />;
};

export default memo(Cable);