import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { sessionService } from 'redux-react-session';
import { configureStore } from '@reduxjs/toolkit';
// Material-UI
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider  } from '@mui/material/styles';
import theme from './theme';
import './index.css';
// Reducers
import reducer from 'reducers';
// Components
import ErrorSnackbar from 'components/Common/ErrorSnackbar/ErrorSnackbar';
import SuccessSnackbar from 'components/Common/SuccessSnackbar/SuccessSnackbar';
import App from 'components/App/App';

const store = configureStore({ reducer, middleware: [thunk] });
const container = document.getElementById('root');
const root = createRoot(container);
sessionService.initSessionService(store);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorSnackbar />
      <SuccessSnackbar />
      <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);