import React, { memo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
// Local files
import { SnackbarErrorContent, Message, Error, Close } from './ErrorSnackbar.styled';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';

const ErrorSnackbar = () => {
  const { hideError } = useError();
  const { isOpen, message } = useCustomSelector(state => state.error);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={hideError}
    >
      <SnackbarErrorContent
        aria-describedby="client-snackbar"
        message={<Message id="client-snackbar"><Error />{message}</Message>}
        action={[
          <IconButton key="close" size="large" aria-label="close" color="inherit" onClick={hideError}>
            <Close />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default memo(ErrorSnackbar);