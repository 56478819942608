import React, { memo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Local files
import { ShowPasswordIconButton } from './Password.styled';
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import { checkEmptyString } from 'helpers';
import useAdmins from 'hooks/useAdmins';
import useApp from 'hooks/useApp';
import useDialogs from 'hooks/useDialogs';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useUsers from 'hooks/useUsers';
import { reasons } from 'helpers/constants';

const { ADMIN, USER } = reasons;

const Password = () => {
  const { closeDrawer } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { updateAdmin } = useAdmins();
  const { updateUser } = useUsers();
  const { closePasswordDialog } = useDialogs();
  const { password: { open, id, reason } } = useCustomSelector(state => state.dialogs);
  const [password, setPassword] = useState({ value: '', error: '' });
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' });
  const [showPasswordSymbols, setShowPasswordSymbols] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleKeyDown = ({ key }) => key === 'Enter' && handleSave();
  
  const handleSave = () => {
    let hasError = false;
    if (!checkEmptyString(password.value)) {
      setPassword(prev => ({ ...prev, error: 'Passwords can\t be empty' }));
      hasError = true;
    }
    if ((!!password.value || !!repeatPassword.value) && (password.value !== repeatPassword.value)) {
      setPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      setRepeatPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      hasError = true;
    }
    
    if (hasError) return;

    setProcessing(true);
    if (reason === ADMIN) {
      updateAdmin({id, password: password.value})
      .then(() => setSuccess('Password successfully changed')
      .then(() => closePasswordDialog()).then(() => closeDrawer(ADMIN)))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    }
    if (reason === USER) {
      updateUser({id, password: password.value})
      .then(() => setSuccess('Password successfully changed')
      .then(() => closePasswordDialog()).then(() => closeDrawer(USER)))
      .catch((error) => setError(error))
      .finally(() => setProcessing(false));
    }   
  }

  const handleExited = () => {
    setPassword({ value: '', error: '' });
    setRepeatPassword({ value: '', error: '' });
    setProcessing(false);
  }

  const saveButton = <Button variant="contained" color="primary" disabled={processing} onClick={handleSave}>{'Change'}</Button>

  return (
    <BaseDialog
      open={open}
      onClose={closePasswordDialog}
      hasTransition={false}
      TransitionProps={{ onExited: handleExited }}
      maxWidth="xs"
      title='Change Password'
      content={
        <>    
          <TextField
            fullWidth
            id="password"
            margin='dense'
            label="Password"
            value={password.value}
            onChange={({ target: { value } }) => { setPassword({ value, error: '' }); setRepeatPassword({ ...repeatPassword, error: '' }) }}
            onKeyDown={handleKeyDown}
            error={!!password.error}
            helperText={password.error}
            type={showPasswordSymbols ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ShowPasswordIconButton
                    size="large"
                    aria-label="show password"
                    disableRipple
                    onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                  >
                    {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </ShowPasswordIconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            margin='dense'
            id="repeatPassword"
            label="Repeat Password"
            value={repeatPassword.value}
            onChange={({ target: { value } }) => { setRepeatPassword({ value, error: '' }); setPassword({ ...password, error: '' }) }}
            onKeyDown={handleKeyDown}
            error={!!repeatPassword.error}
            helperText={repeatPassword.error}
            type={showPasswordSymbols ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ShowPasswordIconButton
                    size="large"
                    aria-label="show password"
                    disableRipple
                    onClick={() => setShowPasswordSymbols(!showPasswordSymbols)}
                  >
                    {showPasswordSymbols ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </ShowPasswordIconButton>
                </InputAdornment>
              )
            }}
          />
        </> 
      }
      actions={<div>{saveButton}</div>}
    />
  );
};

export default memo(Password);