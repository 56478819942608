import { useCallback } from "react";
import { useDispatch } from "react-redux";
//Local files
import {
  getUsers as getUsersAction,
  getUser as getUserAction,
  createUser as createUserAction,
  updateUser as updateUserAction,
  deleteUser as deleteUserAction,
  clearLocalUsers as clearLocalUsersAction,
  clearLocalActiveUser as clearLocalActiveUserAction
} from 'actions/users';

const useUsers = () => {
  const dispatch = useDispatch();
  const getUsers = useCallback(params => dispatch(getUsersAction(params)), [dispatch]);
  const getUser = useCallback(id => dispatch(getUserAction(id)), [dispatch]);
  const createUser = useCallback(user => dispatch(createUserAction(user)), [dispatch]);
  const updateUser = useCallback(user => dispatch(updateUserAction(user)), [dispatch]);
  const deleteUser = useCallback(id => dispatch(deleteUserAction(id)), [dispatch]);
  const clearLocalUsers = useCallback(() => dispatch(clearLocalUsersAction()), [dispatch]);
  const clearLocalActiveUser = useCallback(() => dispatch(clearLocalActiveUserAction()), [dispatch]);

  return { getUsers, getUser, createUser, updateUser, deleteUser, clearLocalUsers, clearLocalActiveUser };

};

export default useUsers;