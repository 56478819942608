export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_BASIC_CHARGE = 'DELETE_BASIC_CHARGE';
export const DELETE_FEE_CHARGE = 'DELETE_FEE_CHARGE';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_DEFAULT_CHARGE = 'DELETE_DEFAULT_CHARGE';
export const DELETE_INSURANCE_ACCOUNT = 'DELETE_INSURANCE_ACCOUNT';
export const DELETE_BASIC_SERVICE = 'DELETE_BASIC_SERVICE';
export const DELETE_ADDITIONAL_SERVICE = 'DELETE_ADDITIONAL_SERVICE';
export const DELETE_TYPE = 'DELETE_TYPE';
export const DELETE_USER = 'DELETE_USER';
