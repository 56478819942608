import styled from '@mui/material/styles/styled';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

export const Success = styled(SnackbarContent)(({theme}) => ({
  backgroundColor: theme.palette.success.main,
  boxShadow: 'none'
}));

export const Message = styled('span')({
  display: 'flex',
  alignItems: 'center'
});

export const CheckCircleOutline = styled(CheckCircleOutlineIcon)({
  width: 20,
  height: 20,
  marginRight: 10
});

export const Close = styled(CloseIcon)({
  width: 20,
  height: 20
});
