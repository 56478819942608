import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { getProfileRoutine, updateProfileRoutine, connectIsscRoutine, disconnectIsscRoutine } from 'actions';

const attributes = ['id', 'email', 'phone'];

export const getProfile = getThunkActionCreator(
  getProfileRoutine,
  async () => {
    getProfile.getProfileCancel && getProfile.getProfileCancel();
    const currentSession = await sessionService.loadSession();
    
    return await axios.get(`/profile`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(c) { getProfile.getProfileCancel = c })
      }
    );
  }
);

export const updateProfile = getThunkActionCreator(
  updateProfileRoutine,
  async (admin) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/profile`, { access_token: currentSession.token, admin });

    return { response, admin };
  }
);

export const connectIssc = getThunkActionCreator(
  connectIsscRoutine,
  async () => {
    const currentSession = await sessionService.loadSession();
    return await axios.post(`/digital_dispatches/connect`, { access_token: currentSession.token });
  }
);

export const disconnectIssc = getThunkActionCreator(
  disconnectIsscRoutine,
  async () => {
    const currentSession = await sessionService.loadSession();
    return await axios.post(`/digital_dispatches/disconnect`, { access_token: currentSession.token });
  }
);