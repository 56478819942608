import {
  createSessionRoutine,
  deleteSessionRoutine
} from 'actions';

const initialState = {
  session: { id: '', platform_type: '', access_token: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createSessionRoutine.SUCCESS: {
      const { session } = action.payload.data;

      return { ...state, session: { ...state.session, ...session } };
    }
    case deleteSessionRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, ...(status === 204 && { session: { id: '', platform_type: '', access_token: '' } }) };
    }
    default: {
      return state;
    }
  };
};

export default reducer;