import axios from 'apis';
import { sessionService } from 'redux-react-session';
import {
  createSessionRoutine,
  deleteSessionRoutine
} from 'actions';

export const createSession = ({ email, password, confirmation_code }) => async (dispatch) => {
  dispatch(createSessionRoutine.request({ email, password, confirmation_code }));
  try {
    const response = await axios.post(`/sessions`,
      {
        session: { platform_type: 'web' },
        email,
        password,
        confirmation_code,
        attributes: ['id', 'access_token']
      }
    );
    await sessionService.saveSession({ token: response.data.session.access_token })
    .then(() => {
      sessionService.saveUser(response.data.session.userable);
      return dispatch(createSessionRoutine.success(response));
    });
  } catch (error) {
    dispatch(createSessionRoutine.failure(error));
    throw error;
  }
};

export const deleteSession = () => async (dispatch) => {
  dispatch(deleteSessionRoutine.request());
  try {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/sessions`,
      {
        params: {
          access_token: currentSession.token
        }
      }
    );
    await sessionService.deleteSession()
    .then(() => {
      sessionService.deleteUser();
      return dispatch(deleteSessionRoutine.success(response));
    });
  } catch (error) {
    dispatch(deleteSessionRoutine.failure(error));
  }
};