import {
  getServicesRoutine,
  getServiceRoutine,
  createServiceRoutine,
  updateServiceRoutine,
  deleteServiceRoutine,
  clearLocalServicesRoutine,
  clearLocalActiveServiceRoutine,
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  service: { id: '', name: '', service_type: '' },
  default_charge: {id: '', charge: null, basic_rate_type: ''}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getServicesRoutine.SUCCESS: {
      const { data: { services, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...services], pagination } } : { ...state, all: { data: services, pagination } };
    }
    case getServiceRoutine.SUCCESS: {
      const { service } = action.payload.data;

      return { ...state, service: { ...state.service, ...service } };
    }
    case createServiceRoutine.SUCCESS: {
      const { service } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[service], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateServiceRoutine.SUCCESS: {
      const { response: { status }, service } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === service.id ? { ...item, ...service } : item) }, ...(status === 204 && { service: { ...state.service, ...service } }) };
    }
    case deleteServiceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalServicesRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveServiceRoutine.SUCCESS: {
      return { ...state, service: { id: '', name: '', service_type: '' } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
