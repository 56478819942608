import React, { memo, useState } from 'react';
import List from '@mui/material/List';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dispatches from '@mui/icons-material/LocalShipping';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useNavigate } from 'react-router-dom';
// Local files
import { Root, Head, Logo, Spacer, LogoutButton } from './Navigation.styled';
import MenuItem from './MenuItem/MenuItem';
import logo from 'assets/logo.png';
import useError from 'hooks/useError';
import useSessions from 'hooks/useSessions';
import useStore from 'hooks/useStore';

const Navigation = () => {
  const navigate = useNavigate();
  const { setError } = useError();
  const { deleteSession } = useSessions();
  const { clearStore } = useStore();
  const [processing, setProcessing] = useState(false);

  const handleLogoutClick = () => {
    setProcessing(true);
    deleteSession()
    .then(() => clearStore().then(() => navigate('/sign_in')))
    .catch(e => setError(e).then(() => setProcessing(false)));
  };

  return (
    <Root>
      <Head>
        <Logo src={logo} alt="logo" />
      </Head>
      <List sx={{width: '100%', pt: '16px'}}>
        <MenuItem to={'admins'} label='Admins' icon={<SupervisorAccountIcon />} />
        <MenuItem to={'companies'} label='Companies' icon={<LocationCityIcon />} />
        <MenuItem to={'insurance_accounts'} label='Insurance Accounts' icon={<BusinessIcon />} />
        <MenuItem to={'users'} label='Users' icon={<AccountCircleIcon />} />
        <MenuItem to={'profile'} label='Profile' icon={<PersonIcon />} />
        <MenuItem to={'dispatches'} label='Dispatches' icon={<Dispatches />} />
        <MenuItem to={'library'} label='Library' icon={<LibraryBooksIcon />} />
      </List>
      <Spacer />
      <LogoutButton
        startIcon={<ExitToAppIcon sx={{display: 'block', transform: 'scale(-1,1)'}} />}
        disabled={processing}
        onClick={handleLogoutClick}
      >
        Logout
      </LogoutButton>
    </Root>
  );
};

export default memo(Navigation);
