export const errorTypes = {
  ACCESS_TOKEN_INVALID: 'access_token_invalid',
};

export const cableMessageTypes = {
  DIGITAL_DISPATCH_INSURANCE_ACCOUNT_UPDATED: 'digital_dispatch_insurance_account_updated',
  WEBHOOK: 'digital_dispatch_webhook_created',
};

export const reasons = {
  ADMIN: 'admin',
  BASIC_CHARGE: 'basic_charge',
  FEE_CHARGE: 'fee_charge',
  COMPANY: 'company',
  DIGITAL_DISPATCH_INSURANCE_ACCOUNT: 'digital_dispatch_insurance_account',
  URGENTLY_INSURANCE_ACCOUNT: 'urgently_insurance_account',
  DISPATCH: 'dispatch',
  SERVICE: 'service',
  TYPE: 'type',
  USER: 'user',
};

export const userRoles = {
  DRIVER: 'driver',
  MANAGER: 'manager',
  OWNER: 'owner',
};

export const dispatchesTypes = [
  { value: 'digital_dispatch', label: 'Digital Dispatch'}, 
  { value: 'urgently', label: 'Urgently'}
];

export const insuranceAccountTypes = [
  { value: 'digital_dispatch_insurance_account', label: 'Digital Dispatch'}, 
  { value: 'urgently_insurance_account', label: 'Urgently'}
];

export const companyTimeZones = [
  { value: 'US/Pacific', label: 'PT' },
  { value: 'US/Mountain', label: 'MT' },
  { value: 'US/Central', label: 'CT' },
  { value: 'US/Eastern', label: 'ET' },
  { value: 'US/Hawaii', label: 'HT' },
  { value: 'US/Alaska', label: 'AT' }
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const urgentlyCategories = [
  'storage', 'tow', 'auto_lockout', 'jump_start', 'fuel', 'flat_tire', 'emergency', 'winch', 'key_replacement', 
  'tire_install', 'motorcycle_tow', 'home_lockout', 'medium_heavy_duty_rsa', 'medium_duty_tow', 'heavy_duty_tow', 
  'secondary_tow', 'accidental_tow', 'taxi', 'repair_shop', 'mobile_mechanic', 'tire_delivery', 'battery_replacement', 
  'transport', 'tow_exchange'
];

export const urgentlyVehicleTypes = ['flat_bed', 'tow_truck', 'rsa', 'covered_carrier'];

export const deliveryTypes = {
  EMAIL: 'email',
  SMS: 'sms',
  PUSH: 'push',
};

export const notificationTypes = {
  order_created: 'New Order',
  order_status_updated: 'Order Status Changed',
};

export const tabsName = {
  SERVICES: 'SERVICES',
  ADDITIONAL: 'ADDITIONAL',
  CHARGES: 'CHARGES',
  FEES: 'FEES',
  TYPES: 'TYPES',
};

export const tabs = [
  { type: 'basic', label: 'SERVICES' },
  { type: 'additional', label: 'ADDITIONAL' },
  { type: 'basic', label: 'CHARGES' },
  { type: 'fee', label: 'FEES' },
  { type: 'type', label: 'TYPES' },
];

export const basicRateTypes = [
  { value: 'fixed', label: 'Fixed' },
  { value: 'per_mile', label: 'Per mile' },
  { value: 'per_hour', label: 'Per hour' },
  { value: 'per_day', label: 'Per day' },
];

export const drawerTypes = {
  FORM: 'form',
  DETAILS: 'details',
};
