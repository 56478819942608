import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';


export const AppBarRoot = styled(AppBar)(({theme}) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main
}));

export const Content = styled('div')({
  minWidth: 0,
  flexShrink: 1,
  flexGrow: 1
});
