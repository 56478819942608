import {
  getInsuranceAccountsRoutine,
  getInsuranceAccountsListRoutine,
  getInsuranceAccountRoutine,
  getCableInsuranceAccountRoutine,
  createInsuranceAccountRoutine,
  updateInsuranceAccountRoutine,
  deleteInsuranceAccountRoutine,
  clearLocalInsuranceAccountsRoutine,
  clearLocalInsuranceAccountsListRoutine,
  clearLocalActiveInsuranceAccountRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  insurance_account: { 
    id: '', 
    company: null,
    location: null,
    insurance_accountable: null,
    insurance_accountable_type: '',
    name: '',
    provider_name: '',
    provider_phone: '',
    order_auto_acceptance: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getInsuranceAccountsRoutine.SUCCESS: {
      const { data: { insurance_accounts, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...insurance_accounts], pagination } } : { ...state, all: { data: insurance_accounts, pagination } };
    }
    case getInsuranceAccountsListRoutine.SUCCESS: {
      const { insurance_accounts, pagination } = action.payload.data;

      return { ...state, list: { data: [...state.list.data, ...insurance_accounts], pagination } };
    }
    case getInsuranceAccountRoutine.SUCCESS: {
      const { insurance_account } = action.payload.data;

      return { ...state, 
        insurance_account: { ...state.insurance_account, ...insurance_account },
        all: { ...state.all, data: _map(state.all.data, (item) => item.id === insurance_account.id ? { ...item, ...insurance_account } : item) }
      };
    }
    case getCableInsuranceAccountRoutine.SUCCESS: {
      const { insurance_account } = action.payload.data;

      return { ...state, 
        all: { ...state.all, data: _map(state.all.data, (item) => item.id === insurance_account.id ? { ...item, ...insurance_account } : item) }
      };
    }
    case createInsuranceAccountRoutine.SUCCESS: {
      const { insurance_account } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[insurance_account], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateInsuranceAccountRoutine.SUCCESS: {
      const { response: { status }, insurance_account } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === insurance_account.id ? { ...item, ...insurance_account } : item) }, ...(status === 204 && { insurance_account: { ...state.insurance_account, ...insurance_account } }) };
    }
    case deleteInsuranceAccountRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalInsuranceAccountsRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalInsuranceAccountsListRoutine.SUCCESS: {
      return { ...state, list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveInsuranceAccountRoutine.SUCCESS: {
      return { ...state,  
        insurance_account: { 
          id: '', 
          name: '', 
          company_id: null,
          digital_dispatch_client_id: null, 
          digital_dispatch_contractor_id: null, 
          digital_dispatch_location_id: null, 
          digital_dispatch_tax_id: null, 
          digital_dispatch_contact_name: null, 
          digital_dispatch_contact_phone: null, 
          digital_dispatch_provider_name: null, 
          digital_dispatch_provider_phone: null,
          digital_dispatch_token: null,
          digital_dispatch_username: null,
          digital_dispatch_logged_in: false,
          digital_dispatch_password: null
        }
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
