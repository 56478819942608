import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';

export const ListButton = styled(ListItemButton)(({theme}) => ({
  flexShrink: 0,
  color: '#fff',
  margin: '4px 0',
  '&.active': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.focus': {
    boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
  }
}));