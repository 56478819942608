import { memo, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Button from '@mui/material/Button';
// Common
import Layout from 'components/App/Layout/Layout';
// Views
const SignIn = lazy(() => import('views/SignIn/SignIn'));
const Admin = lazy(() => import('views/Admin/Admin'));
const Admins = lazy(() => import('views/Admins/Admins'));
const Charge = lazy(() => import('views/Charge/Charge'));
const Company = lazy(() => import('views/Company/Company'));
const Companies = lazy(() => import('views/Companies/Companies'));
const DigitalDispatchInsuranceAccount = lazy(() => import('views/InsuranceAccount/DigitalDispatch/DigitalDispatchInsuranceAccount'));
const UrgentlyInsuranceAccount = lazy(() => import('views/InsuranceAccount/Urgently/UrgentlyInsuranceAccount'));
const InsuranceAccounts = lazy(() => import('views/InsuranceAccounts/InsuranceAccounts'));
const Dispatch = lazy(() => import('views/Dispatch/Dispatch'));
const Dispatches = lazy(() => import('views/Dispatches/Dispatches'));
const Fee = lazy(() => import('views/Fee/Fee'));
const Profile = lazy(() => import('views/Profile/Profile'));
const Service = lazy(() => import('views/Service/Service'));
const Services = lazy(() => import('views/Services/Services'));
const Type = lazy(() => import('views/Type/Type'));
const User = lazy(() => import('views/User/User'));
const Users = lazy(() => import('views/Users/Users'));

const SuspenseWrapper = ({ children }) => <Suspense fallback={null}>{children}</Suspense>;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/sign_in' element={<SuspenseWrapper><SignIn /></SuspenseWrapper>} />
        <Route path='/' element={<Layout />}>
          <Route path='admins' element={<SuspenseWrapper><Admins /></SuspenseWrapper>} />
          <Route path='admins/:admin_id' element={<SuspenseWrapper><Admin /></SuspenseWrapper>} />
          <Route path='companies' element={<SuspenseWrapper><Companies /></SuspenseWrapper>} />
          <Route path='companies/:company_id' element={<SuspenseWrapper><Company /></SuspenseWrapper>} />
          <Route path='insurance_accounts' element={<SuspenseWrapper><InsuranceAccounts /></SuspenseWrapper>} />
          <Route path='insurance_accounts/digital_dispatch/:insurance_account_id' element={<SuspenseWrapper><DigitalDispatchInsuranceAccount /></SuspenseWrapper>} />
          <Route path='insurance_accounts/urgently/:insurance_account_id' element={<SuspenseWrapper><UrgentlyInsuranceAccount /></SuspenseWrapper>} />
          <Route path='dispatches' element={<SuspenseWrapper><Dispatches /></SuspenseWrapper>} />
          <Route path='dispatches/:dispatch_id' element={<SuspenseWrapper><Dispatch /></SuspenseWrapper>} />
          <Route path='library' element={<SuspenseWrapper><Services /></SuspenseWrapper>} />
          <Route path='library/charges/:charge_id' element={<SuspenseWrapper><Charge /></SuspenseWrapper>} />
          <Route path='library/fees/:fee_id' element={<SuspenseWrapper><Fee /></SuspenseWrapper>} />
          <Route path='library/services/:service_id' element={<SuspenseWrapper><Service /></SuspenseWrapper>} />
          <Route path='library/types/:type_id' element={<SuspenseWrapper><Type /></SuspenseWrapper>} />
          <Route path='profile' element={<SuspenseWrapper><Profile /></SuspenseWrapper>} />
          <Route path='users' element={<SuspenseWrapper><Users /></SuspenseWrapper>} />
          <Route path='users/:user_id' element={<SuspenseWrapper><User /></SuspenseWrapper>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <Button variant='contained' color='error' onClick={resetErrorBoundary}>Try again</Button>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);