import axios from 'axios';
import qs from 'qs';

const baseURL = process.env.REACT_APP_BASE_URL;
export const wsURL = process.env.REACT_APP_WS_URL;
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const mapbox = axios.create({ baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places' });

export const CancelToken = axios.CancelToken;
export const mapboxSearchUrl = axios.create({ baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places' });
export const mapboxDirectionUrl = axios.create({ baseURL: 'https://api.mapbox.com/directions/v5/mapbox/driving' });

export default axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
});