import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  createDefaultChargeRoutine,
  updateDefaultChargeRoutine,
  deleteDefaultChargeRoutine
} from 'actions';

export const createDefaultCharge = getThunkActionCreator(
  createDefaultChargeRoutine,
  async ({ id, ...fields }) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/services/${id}/default_charges`, { access_token: currentSession.token, default_charge: fields });
  }
);

export const updateDefaultCharge = getThunkActionCreator(
  updateDefaultChargeRoutine,
  async (default_charge) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/default_charges/${default_charge.id}`, { access_token: currentSession.token, default_charge });

    return { response, default_charge };
  }
);

export const deleteDefaultCharge = getThunkActionCreator(
  deleteDefaultChargeRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/default_charges/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);