import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
// Local files
import { CloseButton } from './Info.styled';
import BaseDialog from 'components/Common/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useDialogs from 'hooks/useDialogs';

const InfoModal = () => {
  const { closeInfoDialog } = useDialogs();
  const { info: { open } } = useCustomSelector(state => state.dialogs);

  return (
    <BaseDialog
      open={open}
      onClose={closeInfoDialog}
      maxWidth="xs"
      title="You can't remove insurance account"
      content={<Typography>If insurance account registered you need deregister and then you can delete insurance account.</Typography>}
      actions={
        <CloseButton variant="contained" color="secondary" onClick={closeInfoDialog}>Close</CloseButton>
      }
    />
  );
};

export default memo(InfoModal);