import {
  getListCompaniesRoutine,
  getCompaniesRoutine,
  getCompanyRoutine,
  createCompanyRoutine,
  updateCompanyRoutine,
  deleteCompanyRoutine,
  clearLocalListCompaniesRoutine,
  clearLocalCompaniesRoutine,
  clearLocalActiveCompanyRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  company: { 
    id: '', 
    locations: [],
    name: '', 
    stripe_account_id: null, 
    stripe_charges_enabled: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getListCompaniesRoutine.SUCCESS: {
      const { companies: data, pagination } = action.payload.data;

      return { ...state, list: { data: _uniqBy([...state.list.data, ...data], 'id'), pagination } };
    }
    case getCompaniesRoutine.SUCCESS: {
      const { data: { companies, pagination } } = action.payload;

      return pagination.limit === 1 ? { ...state, all: { data:  [...state.all.data, ...companies], pagination } } : { ...state, all: { data: companies, pagination } };
    }
    case getCompanyRoutine.SUCCESS: {
      const { company } = action.payload.data;

      return { ...state, 
        company: { ...state.company, ...company },
        all: { ...state.all, data: _map(state.all.data, (item) => item.id === company.id ? { ...item, ...company } : item) }
      };
    }
    case createCompanyRoutine.SUCCESS: {
      const { company } = action.payload.data;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;

      return { 
        ...state,
        all: { 
          data: [...[company], ...oldAllData],
          pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } 
        } 
      };
    }
    case updateCompanyRoutine.SUCCESS: {
      const { response: { status }, company } = action.payload;

      return { ...state, all: { ...state.all, data: _map(state.all.data, (item) => item.id === company.id ? { ...item, ...company } : item) }, ...(status === 204 && { company: { ...state.company, ...company } }) };
    }
    case deleteCompanyRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { 
        ...state, 
        ...(status === 204 && { 
          all: { 
            data: _filter(state.all.data, (item) => item.id !== id), 
            pagination: {
              ...state.all.pagination,
              ...(!!_find(state.all.data, (item) => item.id === id) && { count: state.all.pagination.count - 1 }),
              total_count: state.all.pagination.total_count - 1
            }
          }
        })
      };
    }
    case clearLocalListCompaniesRoutine.SUCCESS: {
      return { ...state, list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalCompaniesRoutine.SUCCESS: {
      return { ...state, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } } };
    }
    case clearLocalActiveCompanyRoutine.SUCCESS: {
      return { ...state,  
        company: { 
          id: '', 
          locations: [],
          name: '', 
          stripe_account_id: null, 
          stripe_charges_enabled: false
        }
      };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
