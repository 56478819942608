import axios, { CancelToken } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getListChargesRoutine,
  getChargesRoutine,
  getChargeRoutine,
  createChargeRoutine,
  updateChargeRoutine,
  deleteChargeRoutine,
  clearLocalListChargesRoutine,
  clearLocalChargesRoutine,
  clearLocalActiveChargeRoutine
} from 'actions';

const attributes = ['id', 'name', 'charge_type', 'basic_rate_types', 'mile_type'];

export const getListCharges = getThunkActionCreator(
  getListChargesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null, charge_type = null }) => {
    getListCharges.getListChargesCancel && getListCharges.getListChargesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/charges`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          ...(!!charge_type && { charge_type }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getListCharges.getListChargesCancel = cancel })
      }
    );
  }
);

export const getCharges = getThunkActionCreator(
  getChargesRoutine,
  async ({ limit = 25, offset = 0, orders = { name: 'asc' }, query = null, charge_type = null }) => {
    getCharges.getChargesCancel && getCharges.getChargesCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/charges`,
      {
        params: {
          access_token: currentSession.token,
          limit,
          offset,
          orders,
          ...(!!query && { query }),
          ...(!!charge_type && { charge_type }),
          attributes
        },
        cancelToken: new CancelToken(function executor(cancel) { getCharges.getChargesCancel = cancel })
      }
    );
  }
);

export const getCharge = getThunkActionCreator(
  getChargeRoutine,
  async (id) => {
    getCharge.getChargeCancel && getCharge.getChargeCancel();
    const currentSession = await sessionService.loadSession();

    return await axios.get(`/charges/${id}`,
      {
        params: { access_token: currentSession.token, attributes },
        cancelToken: new CancelToken(function executor(cancel) { getCharge.getChargeCancel = cancel })
      }
    );
  }
);

export const createCharge = getThunkActionCreator(
  createChargeRoutine,
  async (charge) => {
    const currentSession = await sessionService.loadSession();

    return await axios.post(`/charges`, { access_token: currentSession.token, charge });
  }
);

export const updateCharge = getThunkActionCreator(
  updateChargeRoutine,
  async (charge) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.patch(`/charges/${charge.id}`, { access_token: currentSession.token, charge });

    return { response, charge };
  }
);

export const deleteCharge = getThunkActionCreator(
  deleteChargeRoutine,
  async (id) => {
    const currentSession = await sessionService.loadSession();
    const response = await axios.delete(`/charges/${id}`, { params: { access_token: currentSession.token } });

    return { response, id };
  }
);

export const clearLocalListCharges = getThunkActionCreator(
  clearLocalListChargesRoutine,
  async () => true
);

export const clearLocalCharges = getThunkActionCreator(
  clearLocalChargesRoutine,
  async () => true
);

export const clearLocalActiveCharge = getThunkActionCreator(
  clearLocalActiveChargeRoutine,
  async () => true
);