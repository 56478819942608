import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  setSuccessRoutine,
  hideSuccessRoutine
} from 'actions';

export const setSuccess = getThunkActionCreator(
  setSuccessRoutine,
  async (message) => message
);

export const hideSuccess = getThunkActionCreator(
  hideSuccessRoutine,
  async () => true
);