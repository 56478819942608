import {
  createDefaultChargeRoutine,
  updateDefaultChargeRoutine,
  deleteDefaultChargeRoutine
} from 'actions';

const initialState = {
  default_charge: {id: '', charge: null, basic_rate_type: ''}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createDefaultChargeRoutine.SUCCESS: {
      const { default_charge } = action.payload.data;
    
        return { ...state, default_charge: { ...state.default_charge, ...default_charge } };
    }
    case updateDefaultChargeRoutine.SUCCESS: {
      const { response: { status }, default_charge } = action.payload;
      const updatedDefaultCharge = { ...state.default_charge, ...default_charge };

      return { ...state, ...(status === 204 && { default_charge: updatedDefaultCharge }) };
    }
    case deleteDefaultChargeRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, ...(status === 204 && { default_charge: {id: '', charge: null, basic_rate_type: ''} }) };
    }
    default: {
      return state;
    }
  };
};

export default reducer;
