import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  openConfirmationDialogRoutine, closeConfirmationDialogRoutine,
  openPasswordDialogRoutine, closePasswordDialogRoutine,
  openInfoDialogRoutine, closeInfoDialogRoutine
} from '.';

export const openConfirmationDialog = getThunkActionCreator(openConfirmationDialogRoutine, async ({ type, id }) => ({ type, id }));
export const closeConfirmationDialog = getThunkActionCreator(closeConfirmationDialogRoutine, async () => {});

export const openPasswordDialog = getThunkActionCreator(openPasswordDialogRoutine, async ({ id, reason }) => ({ id, reason }));
export const closePasswordDialog = getThunkActionCreator(closePasswordDialogRoutine, async () => {});

export const openInfoDialog = getThunkActionCreator(openInfoDialogRoutine, async () => {});
export const closeInfoDialog = getThunkActionCreator(closeInfoDialogRoutine, async () => {});
