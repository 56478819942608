import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    openAppDrawer as openAppDrawerAction,
    closeAppDrawer as closeAppDrawerAction
} from 'actions/app';

const useApp = () => {
    const dispatch = useDispatch();
    const openDrawer = useCallback(({ reason, edit, id, type }) => dispatch(openAppDrawerAction({ reason, edit, id, type })), [dispatch]);
    const closeDrawer = useCallback(reason => dispatch(closeAppDrawerAction(reason)), [dispatch]);

    return { openDrawer, closeDrawer };
}

export default useApp;